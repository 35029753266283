<div class="modal-user-hierarchy-location">
  <div class="header">
    <button (click)="close()" class="popup-close-button" tabindex="0"></button>
    <h2 class="title">{{ "SHARED.Location_Access" | translate }}</h2>
  </div>

  <div *ngIf="folders.length" class="medium page-title custom-body">
    <label class="form-check-label custom-folder-lbl" [class.disabled]="!isAllLocationsAvailable">
      <ion-checkbox (ngModelChange)="toggleAll($event)" [(ngModel)]="isCheckedAll" class="message-checkbox"></ion-checkbox>
      {{ "SHARED.All_Locations" | translate }}
    </label>
    <app-treeview #treeviewRef (onCheck)="onGroupSelect()" [disabled]="isCheckedAll" [items]="folders"></app-treeview>
  </div>
</div>

<div class="button-block hierarchy-block">
  <ion-button (click)="close()" class="page-button" color="light">{{ 'SHARED.Cancel' | translate }}</ion-button>
  <ion-button (click)="save()"
              [disabled]="selectedFolders.length === 0"
              class="page-button"
              color="secondary">{{'SHARED.Save_Selections' | translate }}</ion-button>
</div>
