import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { HistoryItemRelatedType } from '@services/observationDetail/observation-detail.service';

enum HistoryEvent {
  Assigned = 'assigned',
  Claimed = 'claimed',
  Created = 'created',
  Dropped = 'dropped',
  Escalated = 'escalated',
  Fixed = 'fixed',
  Resolved = 'resolved',
  TypeChange = 'typechanged',
  Updated = 'updated',
  Logged = 'logged'
}

@Pipe({
  name: 'observationHistoryEvent'
})
export class ObservationHistoryEventPipe implements PipeTransform {

  constructor(
    protected translate: TranslateService
  ) {
  }

  transform(value: string, relatedType?: string,  args?: any): any {
    if (value === 'AddedTo' && relatedType === HistoryItemRelatedType.pdca) {
      const moduleName = this.translate.instant('PDCA.Module_Name');
      const actionName = this.translate.instant('PDCA.Added');
      return `${moduleName}: ${actionName} - `;
    }

    const s = 'DASHPAGES.' + _.lowerCase(value);
    let t = this.translate.instant(s);
    if (t === s) {
      // there was no translation
      t = _.lowerCase(value);
    }
    return t;
  }

}
