import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { UtilsService } from '@services/utils/utils.service';
import { UserService } from '@services/user/user.service';
import { AccountsService } from '@services/accounts/accounts.service';
import { TeamsService } from '@services/teams/teams.service';
import { SettingsService } from '@services/settings/settings.service';
import { ObservationService } from '@services/observations/observation.service';
import { Feature, SubscriberService } from '@services/subscriber/subscriber.service';
import { TranslateService } from '@ngx-translate/core';
import { CheckResponseService } from '@services/checkResponse/check-response.service';
import { AssetsService } from '@services/assets/assets.service';
import { ObservationDetailService } from '@services/observationDetail/observation-detail.service';
import { Observation } from '@services/observations/observation.interfaces';
import { Asset } from '@services/assets/asset.interfaces';

@Injectable({
  providedIn: 'root'
})
export class ColumnBuilderService {
  // let's collect the common columns for tables first
  // 1. OID
  observationID = {
    title: 'SHARED.Response_ID',
    data: 'observationID'
  };

  // 2. Created date
  createdDate = {
    title: 'REPORTING.CREATED',
    data: 'created',
    render: (data, type, row, meta) => {
      if (type === 'display') {
        return this.utils.dateTimeFormat(data, null, true);
      } else if (type === 'export') {
        return this.utils.dateTimeFormat(data, null, false);
      } else if (type === 'filter') {
        return this.utils.dateTimeFormat(data, null, true);
      } else {
        return data;
      }
    }
  };

  // 3. time Open
  timeOpen = {
    title: 'SHARED.TIME_OPEN_1',
    data: 'created',
    render: (data, type, row, meta) => {
      if (type === 'display') {
        return moment(data * 1000).fromNow(true);
      } else if (type === 'export') {
        return Number(((Date.now() - (data * 1000)) / 1000) / 60).toFixed(0);
      } else if (type === 'sort') {
        return data;
      } else if (type === 'filter') {
        return moment(data * 1000).fromNow(true);
      } else {
        return data;
      }
    },
  };

  // 4. Creator of obs
  obsCreator = {
    title: 'REPORTING.CREATOR',
    data: data => this.accountService.fullname(data.userID)
  };

  // 5. owner of obs
  obsOwner = {
    title: 'SHARED.OWNER',
    data: data => this.accountService.fullname(data.ownerID)
  };

  // Assingee for process
  assignedTo = {
    title: 'SHARED.ASSIGNED_TO',
    data: data => this.accountService.fullname(data.ownerID)
  };

  // 6. location of obs
  obsLocation = {
    title: 'SHARED.LOCATION',
    data: data => {
      const locObj = this.userService.findLocation(data.locationID);
      return locObj ? locObj.name : 'N/A';
    }
  };

  // 7. zone of obs
  obsZone = {
    title: 'SHARED.HOME_TS_ZONE',
    data: data => {
      const zoneLocation = this.userService.findLocation(data.locationID);
      const zoneDetails = this.userService.findAnyZone(zoneLocation, data.zoneID);
      return zoneDetails ? zoneDetails.name : 'N/A';
    }
  };

  // 7. zone of obs
  obsLocZone = {
    title: 'SHARED.LOCATION',
    data: data => {
      const locObj = this.userService.findLocation(data.locationID);
      let ret =  locObj ? locObj.name : 'N/A';
      const zoneDetails = this.userService.findAnyZone(locObj, data.zoneID);
      ret += ' / ';
      ret +=  zoneDetails ? zoneDetails.name : 'N/A';
      return ret;
    }
  };

  // 8  team of obs
  obsTeam = {
    title: 'SHARED.TEAM',
    data: data => this.teamService.teamNameByID(data.groupID)
  };

  // 9. serverity of obs
  obsSev = {
    title: 'SHARED.S',
    data: (data) => this.observations.getProperty(data, 'severity'),
    render: (data, type, row, meta) => {
      // tslint:disable-next-line:max-line-length
      if (type === 'display') {
        return '<img  class="image-spacer obs-dot" src=" ' + this.observationDetailService.colorDot(data) + ' ">';
      } else if (type === 'export') {
        return data;
      } else {
        return data;
      }
    }
  };

  // 10. likelihood of obs
  obsLik = {
    title: 'SHARED.L',
    data: (data) => this.observations.getProperty(data, 'likelihood'),
    render: (data, type, row, meta) => {
      // tslint:disable-next-line:max-line-length
      if (type === 'display') {
        return '<img  class="image-spacer obs-dot" src=" ' + this.observationDetailService.colorDot(data) + ' ">';
      } else if (type === 'export') {
        return data;
      } else {
        return data;
      }
    }
  };

  // 11. obs workorder
  obsWorkOrder = {
    title: 'SHARED.Work_Order', data: 'workorder'
  };

  // Implemented By for process
  implementedBy = {
    title: 'SHARED.IMPLEMENTED_BY',
    data: data => data,
    render: (data, type, row, meta) => {
      const userName = this.accountService.fullname(data.ownerID);
      if (type === 'display') {
        if (data.subtype === 'general') {
          return userName;
        } else {
          return null;
        }

      } else if (type === 'export') {
        if (data.subtype === 'general') {
          return userName;
        } else {
          return null;
        }
      } else {
        return data;
      }
    }
  };

  // 12. fixed by
  obsFixedBy = {
    title: 'OTable.FIXED_BY',
    data: data => data,
    render: (data, type, row, meta) => {
      let userName = this.accountService.fullname(data.ownerID);
      if (type === 'display') {
        if (data.workorder) {
          userName += '<span class="wo-small wo-small-spacing" >WO</span>';
        }
        return userName;
      } else if (type === 'export' || type === 'filter' || type === 'sort') {
        return userName;
      } else {
        return data;
      }
    }
  };

  // process implemented date
  implementedDate = {
    title: 'OTable.IMPLEMENTED',
    data: (data) => data,
    render: (data, type, row, meta) => {
      let fixedTime: any = {};
      fixedTime = _.find(data.history, ['activity', 'fixed']);
      if (type === 'display' || type === 'filter') {
        if (fixedTime) {
          return this.utils.dateTimeFormat(fixedTime.time, null, true);
        } else {
          return null;
        }
      } else if (type === 'sort') {
        return fixedTime ? fixedTime.time : 0;
      } else if (type === 'export') {
        if (fixedTime) {
          return this.utils.dateTimeFormat(fixedTime.time, null, false);
        } else {
          return null;
        }
      } else {
        return data;
      }
    }
  };


  // 13. fixed date
  obsFixedDate = {
    title: 'SHARED.FIXED',
    data: (data) => data,
    render: (data, type, row, meta) => {
      let fixedTime: any = {};
      fixedTime = _.find(data.history, ['activity', 'fixed']);
      if (!fixedTime) {
        // that means it was scrapped,
        fixedTime = _.find(data.history, ['activity', 'dropped']);
      }
      if (type === 'display' || type === 'filter') {
        if (fixedTime) {
          return this.utils.dateTimeFormat(fixedTime.time, null, true);
        } else {
          return this.translate.instant('DASHPAGES.NA');
        }
      } else if (type === 'sort') {
        return fixedTime ? fixedTime.time : 0;
      } else if (type === 'export') {
        if (fixedTime) {
          return this.utils.dateTimeFormat(fixedTime.time, null, false);
        } else {
          return this.translate.instant('DASHPAGES.NA');
        }
      } else {
        return data;
      }
    }
  };

  // 14. duration
  obsDuration = {
    title: 'OTable.DURATION',
    data: 'history',
    render: (data, type, row, meta) => {
      if (type === 'display' || type === 'filter') {
        const tDiff = this.observations.openTime(row);
        // end pre-calc
        if (tDiff) {
          // moment.locale( this.translate.getDefaultLang());
          return moment.duration(tDiff * 1000).humanize();
        } else {
          return this.translate.instant('DASHPAGES.NA');
        }
      } else {
        // return this.durationMin(data, 'duration');
        const time = this.observations.openTime(row) / 60;
        return time.toFixed(0);
      }
    }
  };

  // 15 closed date
  obsClosedDate = {
    title: 'OTable.CLOSED',
    data: data => {
      const obj = _.find(data.history, ['activity', 'resolved']);
      if (obj) {
        return obj.time;
      } else {
        return '';
      }
    },
    render: (data, type, row, meta) => {
      if (type === 'display' || type === 'filter') {
        return this.utils.dateTimeFormat(data, null, true);
      } else if (type === 'sort') {
        return data;
      } else if (type === 'export') {
        return this.utils.dateTimeFormat(data, null, false);
      } else {
        return data;
      }
    },
  };

  // 17 closing notes
  // note the class 'table-text-wrap' injection here? this is to make the note column look prety compared to JQM app
  // this maintains the width off the column to whatever we provide in the class
  // injecting class later through datatables would be cumbersome compared to this, but either way should work.
  obsCloseNotes = {
    title: 'OTable.CLOSING_NOTES',
    data: data => data,
    render: (data, type, row, meta) => {
      let noteStr = '';
      let expString = '';
      _.each(data.notes, nObj => {
        if (nObj.subtype === 'closingNotes') {
          // tslint:disable-next-line:max-line-length
          noteStr += `<div class="table-text-wrap"> <img style="margin-right:10px" src="assets/images/check.svg">${nObj.value}</div>`;
          expString += '&bull; ' + nObj.value + '&#8232;';
        }
      });
      if (type === 'display' || type === 'filter') {
        return noteStr;
      } else {
        return expString;
      }
    }
  };

  // 18 category notes
  obsCategoryNotes = {
    title: 'OTable.CATEGORIES',
    data: data => data,
    render: (data, type, row, meta) => {
      let noteString = '';
      let expString = '';
      _.each(data.categories, cObj => {
        const catNote = this.settingsService.getItem('categories', cObj, true);
        if (catNote) {
          // tslint:disable-next-line:max-line-length
          noteString += `<div class="table-text-wrap"> <img style="margin-right:10px" src="assets/images/check.svg">` + catNote.messageTitle + `</div>`;
          expString += '&bull; ' + catNote.messageTitle + '&#8232;';
        }
      });

      if (type === 'display' || type === 'filter') {
        return noteString;
      } else {
        return expString;
      }
    }
  };

  // 19 quality categories.
  qualityCategoryNotes = {
    title: 'OTable.CATEGORIES',
    data: data => data,
    render: (data, type, row, meta) => {
      let noteString = '';
      let expString = '';
      _.each(data.categories, cObj => {
        const catNote = this.settingsService.getItem('qualityCats', cObj, true);
        if (catNote) {
          // tslint:disable-next-line:max-line-length
          noteString += `<div class="table-text-wrap"> <img style="margin-right:10px" src="assets/images/check.svg">${catNote.messageTitle}</div>`;
          expString += '&bull; ' + catNote.messageTitle + '&#8232;';
        }
      });
      if (type === 'display' || type === 'filter') {
        return noteString;
      } else {
        return expString;
      }
    }
  };

  // 20 obs comment
  bbsComment = {
    title: 'OTable.COMMENTS',
    data: data => data,
    render: (data, type, row, meta) => {
      let noteStr = '';
      let expString = '';
      _.each(data.notes, nObj => {
        if (nObj.subtype === 'comment' && nObj.type === 'text') {
          noteStr += `<div class="table-text-wrap"> <img style="margin-right:10px" src="assets/images/check.svg">${nObj.value}</div>`;
          expString += '&bull; ' + nObj.value + '&#8232;';
        }
      });
      if (type === 'display' || type === 'filter') {
        return noteStr;
      } else {
        return expString;
      }
    }
  };

  // 21 obs mitigation
  bbsMitigation = {
    title: 'OTable.MITIGATIONS',
    data: data => data,
    render: (data, type, row, meta) => {
      let noteString = '';
      let expString = '';
      _.each(data.categories, cObj => {
        const catNote = this.settingsService.getItem('mitigations', cObj, true);
        if (catNote) {
          // tslint:disable-next-line:max-line-length
          noteString += `<div class="table-text-wrap"> <img style="margin-right:10px" src="assets/images/check.svg">` + catNote.messageTitle + `</div>`;
          expString += '&bull; ' + catNote.messageTitle + '&#8232;';
        }
      });
      if (type === 'display' || type === 'filter') {
        return noteString;
      } else {
        return expString;
      }
    }
  };

  // 22 Obs behaviors
  bbsBehaviors = {
    title: 'OTable.BEHAVIORS',
    data: data => data,
    render: (data, type, row, meta) => {
      let noteString = '';
      let expString = '';
      _.each(data.categories, cObj => {
        const catNote = this.settingsService.getItem('behaviors', cObj, true);
        if (catNote) {
          // tslint:disable-next-line:max-line-length
          noteString += `<div class="table-text-wrap"> <img style="margin-right:10px" src="assets/images/check.svg">` + catNote.messageTitle + `</div>`;
          expString += '&bull; ' + catNote.messageTitle + '&#8232;';
        }
      });
      if (type === 'display' || type === 'filter') {
        return noteString;
      } else {
        return expString;
      }
    }
  };

  // 23 Obs Compliment
  bbsCompliments = {
    title: 'OTable.BEHAVIORS',
    data: data => data,
    render: (data, type, row, meta) => {
      let noteString = '';
      let expString = '';
      _.each(data.categories, cObj => {
        const catNote = this.settingsService.getItem('compliments', cObj, true);
        if (catNote) {
          // tslint:disable-next-line:max-line-length
          noteString += `<div class="table-text-wrap"> <img style="margin-right:10px" src="assets/images/check.svg">` + catNote.messageTitle + `</div>`;
          expString += '&bull; ' + catNote.messageTitle + '&#8232;';
        }
      });
      if (type === 'display' || type === 'filter') {
        return noteString;
      } else {
        return expString;
      }
    }
  };

  // 24 bbs  Logged date
  bbsLoggedDate = {
    title: 'SHARED.LOGGED',
    data: (data) => {
      const createdTime = _.find(data.history, ['activity', 'created']);
      return createdTime.time;
    },
    render: (data, type, row, meta) => {
      if (type === 'display' || type === 'filter') {
        return this.utils.dateTimeFormat(data, null, true);
      } else if (type === 'sort') {
        return data;
      } else if (type === 'export') {
        return this.utils.dateTimeFormat(data, null, false);
      } else {
        return data;
      }
    },

  };

  // 25 bbs logged by
  bbsLoggedBy = {
    title: 'SHARED.LOGGED_BY',
    data: data => this.accountService.fullname(data.userID)
  };

  // 26 Participants
  tupRecipients = {
    title: 'SHARED.GIVEN_TO',
    data: data => data,
    render: (data, type, row, meta) => {
      let pString = '';
      let expString = '';
      if (!_.isEmpty(data.groups)) {
        _.each(data.groups, gid => {
          // tslint:disable-next-line:max-line-length
          pString += `<div> <img style="margin-right:10px" src="assets/images/check.svg">${this.teamService.teamNameByID(gid)}</div>`;
          expString += '&bull; ' + this.teamService.teamNameByID(gid) + '&#8232;';
        });
      } else {
        _.each(data.recipients, part => {
          // tslint:disable-next-line:max-line-length
          pString += `<div> <img style="margin-right:10px" src="assets/images/check.svg">${this.accountService.fullname(part)}</div>`;
          expString += '&bull; ' + this.accountService.fullname(part) + '&#8232;';
        });
      }

      if (type === 'display' || type === 'filter') {
        return pString;
      } else {
        return expString;
      }
    }
  };

  // 27 pi reason
  piReasons = {
    title: 'OTable.LIMITATION',
    data: data => data,
    render: (data, type, row, meta) => {
      let noteStr = '';
      let expString = '';
      _.each(data.notes, nObj => {
        if (nObj.subtype === 'reason' && nObj.type === 'text') {
          noteStr += `<div> <img style="margin-right:10px" src="assets/images/check.svg">${nObj.value}</div>`;
          expString += '&bull; ' + nObj.value + '&#8232;';
        }
      });
      if (type === 'display' || type === 'filter') {
        return noteStr;
      } else {
        return expString;
      }
    }
  };

  // 28 pi imporvements
  piImprovements = {
    title: 'OTable.IMPROVEMENT',
    data: data => data,
    render: (data, type, row, meta) => {
      let noteStr = '';
      let expString = '';
      _.each(data.notes, nObj => {
        if (nObj.subtype === 'fixed' && nObj.type === 'text') {
          noteStr += `<div> <img style="margin-right:10px" src="assets/images/check.svg">${nObj.value}</div>`;
          expString += '&bull; ' + nObj.value + '&#8232;';
        }
      });
      if (type === 'display' || type === 'filter') {
        return noteStr;
      } else {
        return expString;
      }
    }
  };

  // pi general savings
  piSavings = {
    title: 'SHARED.SAVINGS',
    data: data => data,
    render: (data, type, row, meta) => {
      if (type === 'display') {
        if (data.value && data.subtype === 'general') {
          return '$' + data.value;
        } else {
          return '';
        }
      } else {
        return data.value;
      }
    }
  };

  // 29 PI value
  piValue = {
    title: 'OTable.INPUT',
    data: data => data,
    render: (data, type, row, meta) => {
      if (type === 'display') {
        if (data.value && data.subtype === 'waiting') {
          return data.value + ' mins';
        } else {
          return '';
        }
      } else {
        return data.value;
      }
    }
  };

  // 30 Pi Type
  piType = {
    title: 'SHARED.TYPE',
    data: data => data.subtype === 'production' ? this.translate.instant('COMMONT_SERVICE.Production') : _.upperFirst(data.subtype)
  };

  // 31 fix type
  fixType = {
    title: 'DASHPAGES.CHANGING_TYPE_fixed',
    data: data => {
      if (data.state === 'fixed') {
        return this.translate.instant('MGMT_DETAILS.Repaired');
      } else if (data.state === 'dropped') {
        return this.translate.instant('MGMT_DETAILS.Scrapped');
      }
    }
  };

  // 32 qualityValue
  qualityValue = {
    title: 'OTable.JOB_INFO',
    data: data => data,
    render: (data, type, row, meta) => {
      let expString = '';
      let retStr = '';
      let fields: any = null;
      // gather value data set
      if (data.subtype === 'receiving') {
        fields = this.getCustomFieldData('qualityReceivingFields', data);
      } else if (data.subtype === 'production') {
        fields = this.getCustomFieldData('qualityProductionFields', data);
      }

      // now prepare data set to be displayed and exported
      _.each(fields, (field) => {
        retStr += `<div class="risk-column">
        <div class="descriptor">${field.name}</div>
        <div class="column-content">`;
        expString += '&bull; ' + field.name + '&#8232;';
        _.each(field.values, (v) => {
          retStr += `<div *ngFor="let v of field.values">`;
          if (field.multiple) {
            retStr += `<span style="margin-right: 10px;"> <img *ngIf="field.multiple" src="assets/images/tick_icon.svg"></span>`;
          }
          retStr += `<span>${v}</span><br>
      </div>`;
          if (v == null) {
            v = '--';
          }
          expString += v + '&#8232;';
        });
        retStr += `</div></div>`;
      });


      if (type === 'display' || type === 'filter') {
        return retStr;
      } else {
        return expString;
      }
    }
  };

  // 33. Last Activity
  lastActivity = {
    title: 'MGMT_LIST.LAST_ACTIVITY',
    data: data => data,
    render: (data, type, row, meta) => {
      if (type === 'export') {
        return this.utils.dateTimeFormat(data.lastUpdate, null, false);
      } else if (type === 'sort') {
        return data.lastUpdate;
      } else {
        return this.utils.dateTimeFormat(data.lastUpdate, null, true);
      }
    }
  };

  // 34. Asset closing Categories
  obsAICatsNotes = {
    title: 'OTable.CATEGORIES',
    data: data => data,
    render: (data, type, row, meta) => {
      let noteString = '';
      let expString = '';
      _.each(data.categories, cObj => {
        const catNote = this.settingsService.getItem('aiCats', cObj, true);
        if (catNote) {
          // tslint:disable-next-line:max-line-length
          noteString += `<div class="table-text-wrap"> <img style="margin-right:10px" src="assets/images/check.svg">` + catNote.messageTitle + `</div>`;
          expString += '&bull; ' + catNote.messageTitle + '&#8232;';
        }
      });

      if (type === 'display' || type === 'filter') {
        return noteString;
      } else {
        return expString;
      }
    }
  };

  submitted = {
    title: 'SHARED.SUBMITTED',
    data: data => data,
    render: (data, type, row, meta) => {
      const createdTime = _.find(data.history, ['activity', 'created']);
      if (type === 'export') {
        return this.utils.dateTimeFormat(createdTime.time, null, false);
      } else if (type === 'sort') {
        return createdTime ? createdTime.time : 0;
      } else {
        return this.utils.dateTimeFormat(createdTime.time, null, true);
      }
    }
  };

  submittedBy = {
    title: 'SHARED.SUBMITTED_BY',
    data: data => this.accountService.fullname(data.userID)
  };

  // general note
  generalNote = {
    title: 'OTable.NOTES',
    data: data => data,
    render: (data, type, row, meta) => {
      let noteStr = '';
      let expString = '';
      _.each(data.notes, nObj => {
        if (nObj.subtype !== 'closingNotes') {
          // tslint:disable-next-line:max-line-length
          noteStr += `<div class="table-text-wrap"> <img style="margin-right:10px" src="assets/images/check.svg">${nObj.value}</div>`;
          expString += '&bull; ' + nObj.value + '&#8232;';
        }
      });
      if (type === 'display' || type === 'filter') {
        return noteStr;
      } else {
        return expString;
      }
    }
  };

  impact = {
    title: 'OTable.IMPACT',
    data: data => data.impact
  };

  tags = {
    title: 'OTable.TAGS',
    data: data => data,
    render: (data: any) => {
      let tagNameList = '';

      _.each(data.tags, (tagID: number) => {
        const tagItem: any = _.find(this.settingsService.customTags.data, <any>{tagID});

        if (tagItem) {
          tagNameList += `<div class="table-text-wrap">${tagItem.tag}</div>`;
        }
      });

      return tagNameList;
    }
  };

  checkName = {
    title: 'SHARED.Deployment_Name',
    data: data => data,
    render: (data: any) => {
      if (data.deployment) {
        return data.deployment;
      } else {
        // see if this can be retrieved from respObj
        const depstr = this.checkResponseService.getDeploymentByID(data.deploymentID);
        if (depstr) {
          return depstr;
        } else {
          return '';
        }
      }
    }
  };
  target = {
    title: 'OTable.TARGET',
    data: data => data,
    render: (data: any) => {
      if (data.targetSignature) {
        const splitSig = _.split(data.targetSignature, ':');
        // Translate these stuff
        let targStr = '';
        if (splitSig[0] == 'loc') {
          targStr = 'Zone' + ': ' + this.userService.findAnyZoneNoLoc(splitSig[2]).name;
        } else if (splitSig[0] == 'asset') {
          const targObj = this.assetService.getAssetById(+splitSig[1]);
          if (targObj && targObj.length) {
            targStr = 'Asset' + ': ' + targObj[0].name;
          } else {
            targStr = 'Asset:unknown';
          }
        } else if (splitSig[0] == 'worker') {
          targStr = 'Worker' + ': ' + this.accountService.getCompactName(+splitSig[1]);
        }
        return targStr;
      } else {
        return '';
      }
    }
  };

  resolvedDuration = {
    title: 'OTable.DURATION',
    data: 'history',
    render: (data, type, row, meta) => {
      if (type === 'display' || type === 'filter') {
        // pre=-calc
        const fixed = _.find(data, ['activity', 'resolved']);
        const created = _.find(data, ['activity', 'created']);
        let tDiff = 0;
        if (fixed && created) {
          tDiff = fixed.time - created.time;
        }
        // end pre-calc
        if (tDiff) {
          // moment.locale( this.translate.getDefaultLang());
          return moment.duration(tDiff * 1000).humanize();
        } else {
          return this.translate.instant('DASHPAGES.NA');
        }
      } else {
        return this.durationMin(data, 'duration');
      }
    }
  };

  resolvedBy = {
    title: 'DASHPAGES.Resolved_By',
    data: (data) => data,
    render: (data, type, row, meta) => {
      let resolver: any = {};
      resolver = _.find(data.history, ['activity', 'resolved']);
      if (resolver) {
        const userName = this.accountService.fullname(resolver.userID);
        return userName;
      } else {
        return 'N/A';
      }
    }
  };

  resolvedDate = {
    title: 'DASHPAGES.resolved',
    data: (data) => data,
    render: (data, type, row, meta) => {
      let fixedTime: any = {};
      fixedTime = _.find(data.history, ['activity', 'resolved']);
      if (type === 'display' || type === 'filter') {
        if (fixedTime) {
          return this.utils.dateTimeFormat(fixedTime.time, null, true);
        } else {
          return this.translate.instant('DASHPAGES.NA');
        }
      } else if (type === 'sort') {
        return fixedTime ? fixedTime.time : 0;
      } else if (type === 'export') {
        return this.utils.dateTimeFormat(fixedTime.time, null, false);
      } else {
        return data;
      }
    }
  };


  piCategory = {
    title: 'OTable.CATEGORIES',
    data: data => data,
    render: (data, type, row, meta) => {
      let noteString = '';
      let expString = '';
      _.each(data.categories, cObj => {
        const catNote = this.settingsService.getItem('piCats', cObj, true);
        if (catNote) {
          // tslint:disable-next-line:max-line-length
          noteString += `<div class="table-text-wrap"> <img style="margin-right:10px" src="assets/images/check.svg">` + catNote.messageTitle + `</div>`;
          expString += '&bull; ' + catNote.messageTitle + '&#8232;';
        }
      });

      if (type === 'display' || type === 'filter') {
        return noteString;
      } else {
        return expString;
      }
    }
  };


  caClosingCategory = {
    title: 'OTable.CATEGORIES',
    data: data => data,
    render: (data, type, row, meta) => {
      let noteString = '';
      let expString = '';
      let catObj: any = '';
      if (!data.caCategory) {
        return ' ';
      }
      catObj = _.split(data.caCategory, ':');
      if (catObj[0] === 'none') {
        return ' ';
      } else {

        const catNote = this.settingsService.getItem(this.settingsService.tableMapNames[(catObj[0])], +catObj[1], true);
        if (catNote) {
          // tslint:disable-next-line:max-line-length
          noteString += `<div class="table-text-wrap"> <img style="margin-right:10px" src="assets/images/check.svg">` + catNote.messageTitle + `</div>`;
          expString += '&bull; ' + catNote.messageTitle + '&#8232;';
        }
      }
      if (type === 'display' || type === 'filter') {
        return noteString;
      } else {
        return expString;
      }
    }
  };

  obsType = {
    title: 'SHARED.Observation_Type',
    data: data => data,
    render: (data, type, row, meta) => _.capitalize(data.type)
  };

  obsAsset = {
    title: 'MGMT_LIST.Asset',
    data: data => data,
    render: (observation: Observation) => {
      let asset: Asset;

      if (observation.subjectType === 'asset') {
        asset = this.assetService.getAssetById(observation.subjectID)?.[0];
      }

      return asset?.name || this.translate.instant('SHARED.None');
    }
  }

  obsLoggedAssetStatus = {
    title: 'SHARED.Status_Logged',
    data: (data) => data,
    isValid: () => this.subscriberService.usesFeature(Feature.PROPERTIES),
    render: (data, type) => {
      const status = this.observationDetailService.getAIStatus(data);

      if (type === 'display') {
        if (status?.type) {
          return `
            <div>
              <span class="badge-label ${status.type}">${this.translate.instant(status.title)}</span>
            </div>
        `;
        } else {
          return '';
        }
      } else if (type === 'export') {
        return data;
      } else {
        return status?.type || data;
      }
    }
  };

  obsFixedAssetStatus = {
    title: 'SHARED.Status_Fixed',
    data: (data) => data,
    isValid: () => this.subscriberService.usesFeature(Feature.PROPERTIES),
    render: (data, type) => {
      const status = this.observationDetailService.getAIStatus(data, true);
      if (type === 'display') {
        if (status?.type) {
          return `
            <div>
              <span class="badge-label ${status.type}">${this.translate.instant(status.title)}</span>
            </div>
        `;
        } else {
          return '';
        }
      } else if (type === 'export') {
        return data;
      } else {
        return status?.type || data;
      }
    }
  };

  constructor(private utils: UtilsService,
              private logger: NGXLogger,
              private userService: UserService,
              private accountService: AccountsService,
              private teamService: TeamsService,
              private settingsService: SettingsService,
              private subscriberService: SubscriberService,
              private observations: ObservationService,
              private observationDetailService: ObservationDetailService,
              private translate: TranslateService,
              private checkResponseService: CheckResponseService,
              private assetService: AssetsService
  ) {
  }

  public addIconAndTranslate(elements) {
    return _.map(elements, (element: any) => {
      element.title = `${this.translate.instant(element.title)} <span class="sort-icon"></span>`;
      return element;
    });
  }

  /**
   *
   * @param tab - the tab object selected from observation page
   * collects the column definition and what data to display from the constants above, for any new type of
   * attribute added to table, add to the definiton above for each property type, thens simply put it in array as example below
   * for your table
   * @returns - Array of column definition
   */
  public buildTableColumn(tab) {
    //this.logger.log(tab.id);
    let array: any = [];
    if (tab.id === 'open') {
      array = [
        this.observationID,
        this.createdDate,
        this.timeOpen,
        this.obsCreator,
        this.obsOwner,
        this.obsLocation,
        this.obsZone,
        this.obsTeam,
        this.obsSev,
        this.obsLik,
        this.generalNote
      ];
    } else if (tab.id === 'workorder') {
      array = [
        this.observationID,
        this.createdDate,
        this.timeOpen,
        this.obsCreator,
        this.obsOwner,
        this.obsLocation,
        this.obsZone,
        this.obsTeam,
        this.obsSev,
        this.obsLik,
        this.generalNote,
        this.obsWorkOrder
      ];
    } else if (tab.id === 'fixed') {
      array = [
        this.observationID,
        this.createdDate,
        this.obsFixedDate,
        this.obsDuration,
        this.obsCreator,
        this.obsFixedBy,
        this.obsLocation,
        this.obsZone,
        this.obsTeam,
        this.obsSev,
        this.obsLik,
        this.generalNote,
        this.obsWorkOrder
      ];
    } else if (tab.id === 'closed') {
      array = [
        this.observationID,
        this.createdDate,
        this.obsFixedDate,
        this.obsDuration,
        this.obsClosedDate,
        this.obsCreator,
        this.obsFixedBy,
        this.obsLocation,
        this.obsZone,
        this.obsTeam,
        this.obsSev,
        this.obsLik,
        this.generalNote,
        this.obsWorkOrder,
        this.obsCategoryNotes,
        this.tags,
        this.impact,
        this.obsCloseNotes
      ];
    } else if (tab.id === 'qualityOpen') {
      array = [
        this.observationID,
        this.createdDate,
        this.timeOpen,
        this.obsCreator,
        this.obsOwner,
        this.obsLocation,
        this.obsZone,
        this.obsTeam,
        this.piType,
        this.qualityValue,
        this.generalNote
      ];
    } else if (tab.id === 'qualityReceiving') {
      array = [
        this.observationID,
        this.createdDate,
        this.timeOpen,
        this.obsCreator,
        this.obsOwner,
        this.obsLocation,
        this.obsZone,
        this.obsTeam,
        this.piType,
        this.qualityValue,
        this.generalNote
      ];
    } else if (tab.id === 'qualityFixed') {
      array = [
        this.observationID,
        this.createdDate,
        this.obsFixedDate,
        this.obsDuration,
        this.obsCreator,
        this.obsFixedBy,
        this.obsLocation,
        this.obsZone,
        this.obsTeam,
        this.fixType,
        this.qualityValue,
        this.generalNote
      ];
    } else if (tab.id === 'qualityClosed') {
      array = [
        this.observationID,
        this.createdDate,
        this.obsFixedDate,
        this.obsDuration,
        this.obsClosedDate,
        this.obsCreator,
        this.obsFixedBy,
        this.obsLocation,
        this.obsZone,
        this.obsTeam,
        this.piType,
        this.qualityValue,
        this.generalNote,
        this.qualityCategoryNotes,
        this.tags,
        this.impact,
        this.obsCloseNotes
      ];
    } else if (tab.id === 'coachingData') {
      array = [
        this.observationID,
        this.bbsLoggedDate,
        this.bbsLoggedBy,
        this.obsLocation,
        this.obsZone,
        this.obsTeam,
        this.bbsBehaviors,
        this.bbsMitigation,
        this.bbsComment
      ];
    } else if (tab.id === 'complimentData') {
      array = [
        this.observationID,
        this.bbsLoggedDate,
        this.bbsLoggedBy,
        this.tupRecipients,
        this.obsLocation,
        this.obsZone,
        this.obsTeam,
        this.bbsCompliments,
        this.bbsComment
      ];
    } else if (tab.id === 'processSubmitted') {
      array = [
        this.observationID,
        this.submitted,
        this.submittedBy,
        this.timeOpen,
        this.obsLocation,
        this.obsZone,
        this.obsTeam,
        this.generalNote
      ];
    } else if (tab.id === 'processAssigned') {
      array = [
        this.observationID,
        this.submitted,
        this.submittedBy,
        this.obsOwner,
        this.assignedTo,
        this.timeOpen,
        this.obsLocation,
        this.obsZone,
        this.obsTeam,
        this.generalNote
      ];
    } else if (tab.id === 'processImplemented') {
      array = [
        this.observationID,
        this.submitted,
        this.submittedBy,
        this.obsOwner,
        this.implementedDate,
        this.implementedBy,
        this.obsDuration,
        this.obsLocation,
        this.obsZone,
        this.obsTeam,
        this.generalNote
      ];
    } else if (tab.id === 'processClosed') {
      array = [
        this.observationID,
        this.submitted,
        this.submittedBy,
        this.obsOwner,
        this.implementedDate,
        this.implementedBy,
        this.obsDuration,
        this.obsLocation,
        this.obsZone,
        this.obsTeam,
        this.piType,
        this.piValue,
        this.generalNote,
        this.tags,
        this.piSavings,
        this.obsCloseNotes,
        this.piCategory,
        // this.piReasons,
        // this.piImprovements
      ];
    } else if (tab.id === 'caOpen') {
      array = [
        this.observationID,
        this.checkName,
        this.target,
        this.submitted,
        this.submittedBy,
        this.obsOwner,
        this.obsTeam
      ];
    } else if (tab.id === 'caWorkorder') {
      array = [
        this.observationID,
        this.checkName,
        this.target,
        this.submitted,
        this.submittedBy,
        this.obsOwner,
        this.obsTeam
      ];
    } else if (tab.id === 'caClosed') {
      array = [
        this.observationID,
        this.checkName,
        this.target,
        this.resolvedDate,
        this.resolvedDuration,
        this.resolvedBy,
        this.obsTeam,
        this.caClosingCategory
      ];
    } else if (tab.id === 'exploreTable') {
      array = [
        this.observationID,
        this.obsType,
        this.submitted,
        this.submittedBy,
        this.obsOwner,
        this.implementedDate,
        this.implementedBy,
        this.obsLocation
      ];
    } else if (_.includes(['submitted', 'addressed'], tab.id)) {
      array = [
        this.observationID,
        this.lastActivity,
        this.submittedBy,
        this.obsLocZone,
        this.obsTeam,
        this.generalNote
      ];
    } else  if (tab.id === 'assetOpen') {
      array = [
        this.observationID,
        this.createdDate,
        this.timeOpen,
        this.obsAsset,
        this.obsCreator,
        this.obsOwner,
        this.obsLocation,
        this.obsZone,
        this.obsTeam,
        this.obsLoggedAssetStatus,
        this.generalNote
      ];
    } else if (tab.id === 'assetWorkorder') {
      array = [
        this.observationID,
        this.createdDate,
        this.timeOpen,
        this.obsCreator,
        this.obsOwner,
        this.obsLocation,
        this.obsZone,
        this.obsTeam,
        this.obsAsset,
        this.obsLoggedAssetStatus,
        this.generalNote
      ];
    } else if (tab.id === 'assetFixed') {
      array = [
        this.observationID,
        this.createdDate,
        this.obsFixedDate,
        this.obsDuration,
        this.obsCreator,
        this.obsFixedBy,
        this.obsLocation,
        this.obsZone,
        this.obsTeam,
        this.obsAsset,
        this.obsLoggedAssetStatus,
        this.obsFixedAssetStatus,
        this.generalNote,
        this.obsWorkOrder
      ];
    } else if (tab.id === 'assetClosed') {
      array = [
        this.observationID,
        this.createdDate,
        this.obsFixedDate,
        this.obsDuration,
        this.obsClosedDate,
        this.obsCreator,
        this.obsFixedBy,
        this.obsLocation,
        this.obsZone,
        this.obsTeam,
        this.obsAsset,
        this.obsLoggedAssetStatus,
        this.obsFixedAssetStatus,
        this.generalNote,
        this.obsWorkOrder,
        this.obsAICatsNotes,
        this.tags,
        this.impact,
        this.obsCloseNotes
      ];
    }

    if (array.length) {
      array = this.addIconAndTranslate(_.cloneDeep(array));
    }

    return array;
  }

  public defaultHiddenCols(tab) {
    const hideArray = [];
    if (tab.id === 'open') {
      hideArray.push(10);
    } else if (tab.id === 'workorder') {
      hideArray.push(10);
    } else if (tab.id === 'fixed') {
      hideArray.push(1, 2, 8, 11);
    } else if (tab.id === 'closed') {
      hideArray.push(1, 2, 4, 13, 14, 16);
    } else if (tab.id === 'qualityOpen') {
      hideArray.push(9, 10);
    } else if (tab.id === 'qualityReceiving') {
      hideArray.push(9, 10);
    } else if (tab.id === 'qualityFixed') {
      hideArray.push(10, 11);
    } else if (tab.id === 'qualityClosed') {
      hideArray.push(1, 2, 3, 4, 9, 11, 12, 13);
    } else if (tab.id === 'coachingData') {
      //  hideArray.push(6, 7);
    } else if (tab.id === 'complimentData') {
      //  hideArray.push (7);
    } else if (tab.id === 'processSubmitted') {
      hideArray.push(7);
    } else if (tab.id === 'processAssigned') {
      hideArray.push(8);
    } else if (tab.id === 'processImplemented') {
      hideArray.push(9);
    } else if (tab.id === 'processClosed') {
      hideArray.push(1, 5, 6, 7, 11);
    }
    return hideArray;
  }

  /**
   *
   * @deprecated - this will not work with our configuration
   */
  public createdRowFunction(tab) {
    return (row, data, dataIndex) => {
      // export result in minute for created date.
      $(row).find('td:eq(2)').attr('data-filter', '');
      $(row).find('td:eq(2)').attr('data-minutes', this.durationMin(data.history, 'elapsed'));


      $(row).find('td:eq(8)').attr('data-filter', '');
      $(row).find('td:eq(8)').attr('data-sort', data.severity);

      $(row).find('td:eq(9)').attr('data-filter', '');
      $(row).find('td:eq(9)').attr('data-sort', data.likelihood);
    };
  }

  public getQualityDetailData(oid) {
    const retObj: any = {};
    const observation = this.observations.observations.data[oid];
    const subtype: string = this.observations.getProperty(observation, 'subtype');
    retObj.subtype = subtype;
    // following prop will come from backend eventually
    if (subtype === 'receiving') {
      retObj.qualType = this.translate.instant('SHARED.Receiving');
      retObj.fields = this.getCustomFieldData('qualityReceivingFields', observation);
    } else if (subtype === 'production') {
      retObj.qualType = this.translate.instant('COMMONT_SERVICE.Production');
      retObj.fields = this.getCustomFieldData('qualityProductionFields', observation);
    }

    if (observation.state === 'fixed') {
      retObj.fixType = 'Repaired';
    } else if (observation.state === 'dropped') {
      retObj.fixType = 'Scrapped';
    }
    return retObj;
  }

  private durationMin(val, string) {
    let a = null;
    let b = null;
    val.forEach((item) => {
      if (item.activity === 'created') {
        a = item.time;
      }
      if (item.activity === 'fixed') {
        b = item.time;
      }
      if (item.activity === 'dropped') {
        b = item.time;
      }
    });
    if (string === 'elapsed') {
      return Number(((Date.now() - (a * 1000)) / 1000) / 60).toFixed(0);
    }
    if (string === 'duration') {
      if (a && b) {
        return +Number((b - a) / 60).toFixed(0);
      } else {
        return null;
      }
    }
  }

  private getCustomFieldData(type: string, obs: any): any {
    // pull in the custom field definitions and the associated data
    const f = this.subscriberService.getPreference(type);
    let v = _.get(obs, 'value', {});
    if (typeof (v) === 'string') {
      try {
        v = JSON.parse(v);
      } catch (e) {
        v = {};
      }
    }
    const ret = [];
    if (f) {
      // we have the fields
      _.each(f.data, (data, idx) => {
        const r = {name: data.name, uuid: data.uuid, multiple: data.canAdd, values: []};
        let vals = _.filter(v, ['uuid', data.uuid]);
        if (!vals.length) {
          vals = _.filter(v, ['name', data.name]);
        }
        vals = _.filter(vals, ref => {
          if (ref.hasOwnProperty('value') && ref.value !== '' && ref.value !== null) {
            return true;
          }
        });
        if (!vals.length) {
          vals = [{value: '--'}];
        }
        _.each(vals, (ref) => {
          if (ref.value !== '') {
            r.values.push(ref.value);
          }
        });
        ret.push(r);
      });
    }
    return ret;
  }

}
