import { Injectable } from '@angular/core';

import { IObjectStringKeyMap } from '@shared/models';
import { UserService } from '@services/user/user.service';
import { UtilsService } from '@services/utils/utils.service';
import {
  CustomField,
  CustomFieldData,
  CustomFieldDataValue,
  CustomFieldType,
  FieldCustomCreator,
} from './../custom-fields.interfaces';
import {
  IListMenuConfig,
  ListMenuSelectMode,
  ListMenuViewType
} from '@shared/components/list-menu/list-menu.component';

import moment from 'moment';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { each, filter, find, includes, keyBy, map, merge, omit, split } from 'lodash';
import { TeamsService } from '@services';


@Injectable({
  providedIn: 'root'
})
export class CustomFieldsService {

  constructor(
    private translate: TranslateService,
    private userService: UserService,
    private teamService: TeamsService,
    private utilsService: UtilsService
  ) {}

  public buildFields(fields: CustomField[], fieldCustomCreator?: FieldCustomCreator): FormlyFieldConfig[] {
    return filter(map(fields, (field: CustomField) => {
      let fieldFormConfig: FormlyFieldConfig = this.buildField(field);

      if (field.canAdd) {
        fieldFormConfig = this.buildFieldAsGroup(fieldFormConfig);
      }

      if (fieldCustomCreator) {
        merge(fieldFormConfig, fieldCustomCreator(field));
      }

      if (this.isFieldTypeAllowed(field.type)) {
        return fieldFormConfig;
      }
    }));
  }

  public parseFieldValue(value: CustomFieldDataValue, type: CustomFieldType, noValue: string = '--', fieldDef?: FormlyFieldConfig): CustomFieldDataValue {
    if (value) {
      if (type === CustomFieldType.Datetime) {
        value = this.utilsService.dateTimeFormat(+value / 1000);
      } else if (type === CustomFieldType.Date) {
        value = moment(value).format('MMM DD, YYYY');
      } else if (type === CustomFieldType.User) {
        value = this.userService.getFullname(value);
      } else if (type === CustomFieldType.Team) {
        value = this.teamService.teamNameByID(value);
      } else if (type === CustomFieldType.Users) {
        value = filter(map(value as number[], (userID) => this.userService.getFullname(+userID) ) );
      } else if (type === CustomFieldType.Teams) {
        value = filter(map(value as number[], (teamID) => this.teamService.teamNameByID(+teamID)));
      } else if (type === CustomFieldType.Select || type === CustomFieldType.SingleSelect || type === CustomFieldType.Multiselect) {
        value = filter(map(value as string[], (id) => {
          if (fieldDef) {
            const opts: any = fieldDef?.props?.options || fieldDef?.options;
            if (opts) {
              const item = find(opts, { id }) as any;
              if (item && item?.description) {
                return item.description;
              }
            }
          }
        }));
      }
    }

    return value || noValue;
  }

  public parseFieldsData(fieldsData: CustomFieldData[], customFields: CustomField[]): IObjectStringKeyMap<CustomFieldDataValue> {
    const formData: IObjectStringKeyMap<CustomFieldDataValue> = {};
    const customFieldsMap = keyBy(customFields, 'uuid');

    each(fieldsData, (field) => {
      if (customFieldsMap[field?.uuid]?.canAdd) {
        if (!formData[field.uuid]) {
          formData[field.uuid]  = [];
        }
        (formData[field.uuid] as (string | number)[]).push(field.value as string);
      } else {
        formData[field.uuid] = field.value;
      }
    });

    return formData;
  }

  public parseOptions(listSource: string): { id: string; description: string }[] {
    if (listSource) {
      const rows = split(listSource, /\n/);

      return filter(map(rows, (theRow) => {
        const found = theRow.match(/(?<id>[^,]+),\s*(?<description>.*)$/);

        if (found?.groups) {
          return { id: found.groups.id, description: found.groups.description };
        }
      }));
    }
  }

  private isFieldTypeAllowed(type: CustomFieldType) {
    return includes([
      CustomFieldType.Barcode,
      CustomFieldType.Input,
      CustomFieldType.Textarea,
      CustomFieldType.SingleSelect,
      CustomFieldType.Multiselect,
      CustomFieldType.Number,
      CustomFieldType.User,
      CustomFieldType.Team,
      CustomFieldType.Users,
      CustomFieldType.Teams,
      CustomFieldType.Date,
      CustomFieldType.Datetime,
      CustomFieldType.NumberBarcode
    ], type);
  }

  private buildFieldAsGroup(fieldFormConfig: FormlyFieldConfig): FormlyFieldConfig {
    const omittedProperties = ['key', 'props.label', 'props.labelPosition'];

    return {
      key: fieldFormConfig.key,
      type: 'group',
      props: {
        label: fieldFormConfig?.props?.label,
        labelPosition: fieldFormConfig?.props?.labelPosition,
      },
      fieldArray: omit(fieldFormConfig, omittedProperties)
    };
  }

  private buildField(field: CustomField): FormlyFieldConfig {
    let fieldFormConfig: FormlyFieldConfig = {
      key: field.uuid,
      type: field.type,
      props: {
        label: this.userService.getTranslatedValue(field, 'name'),
        labelPosition: 'stacked',
        required: field.required,
        attributes: {
          originalType: field.type
        }
      },

    };
    const defaultSelectOptions: FormlyFieldConfig = {
      type: 'select',
      props: {
        valueProp: 'id',
        labelProp: 'description',
        okText: this.translate.instant('SHARED.Ok'),
        cancelText: this.translate.instant('SHARED.Cancel'),
        attributes: {
          originalType: field.type
        }
      }
    };

    switch (field.type) {
      case CustomFieldType.SingleSelect:
        merge(fieldFormConfig, defaultSelectOptions, {
          props: {
            interface: 'popover',
            options: this.parseOptions(field.menuItems)
          }
        });
        break;
      case CustomFieldType.Multiselect:
        merge(fieldFormConfig, defaultSelectOptions, {
          props: {
            options: this.parseOptions(field.menuItems),
            multiple: true
          }
        });
        break;
      case CustomFieldType.User:
        merge(fieldFormConfig, defaultSelectOptions, {
          type: 'user-team-select',
          props: {
            listMenuConfig: <IListMenuConfig>{
              type: ListMenuViewType.User,
              selectMode: ListMenuSelectMode.Single
            }
          }
        });
        break;
      case CustomFieldType.Users:
        merge(fieldFormConfig, defaultSelectOptions, {
          type: 'user-team-select',
          props: {
            listMenuConfig: <IListMenuConfig>{
              type: ListMenuViewType.User,
              selectMode: ListMenuSelectMode.Multiple
            }
          }
        });
        break;
      case CustomFieldType.Team:
        merge(fieldFormConfig, defaultSelectOptions, {
          type: 'user-team-select',
          props: {
            listMenuConfig: <IListMenuConfig>{
              type: ListMenuViewType.Team,
              selectMode: ListMenuSelectMode.Single
            }
          }
        });
        break;
      case CustomFieldType.Teams:
        merge(fieldFormConfig, defaultSelectOptions, {
          type: 'user-team-select',
          props: {
            listMenuConfig: <IListMenuConfig>{
              type: ListMenuViewType.Team,
              selectMode: ListMenuSelectMode.Multiple
            }
          }
        });
        break;
      case CustomFieldType.Date:
        merge(fieldFormConfig, {
          type: 'datetime',
          props: {
            presentation: 'date'
          }
        });
        break;
      case CustomFieldType.Datetime:
        merge(fieldFormConfig, {
          type: 'datetime',
          props: {
            presentation: 'date-time'
          }
        });
        break;
    }

    return fieldFormConfig;
  }
}
