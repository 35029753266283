import { FormlyFieldConfig } from '@ngx-formly/core';

export enum CustomFieldType {
  Input = 'input',
  Barcode = 'barcode',
  Textarea = 'textarea',
  Date = 'date',
  Datetime = 'datetime',
  SingleSelect = 'singleselect',
  Multiselect = 'multiselect',
  User = 'user',
  Users = 'users',
  Team = 'team',
  Teams = 'teams',
  Number = 'number',
  NumberBarcode = 'number-barcode',
  Select = 'select'
}

export interface CustomField {
  barcode: boolean;
  canAdd: boolean;
  default: string;
  menuItems: string;
  name: string;
  required: boolean;
  selected: boolean;
  translations: any[];
  type: CustomFieldType;
  uuid: string;
}

export interface CustomFields {
  addButton: boolean;
  data: CustomField[];
}


export interface CustomFieldData {
  uuid: string;
  value: CustomFieldDataValue;
}

export type CustomFieldDataValue = string | number | (number | string)[];

export interface CustomFieldSelectOption {
  id: number;
  description: string;
}

export type FieldCustomCreator = (field: CustomField) => FormlyFieldConfig;
