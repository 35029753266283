import { Injectable, Injector } from '@angular/core';

import { FormField, SubscriberService } from '@services';
import { BaseField, ReportBaseClass } from '@modules/reporting/models/types/base';
import { CheckDetailModelService } from '@modules/reporting/models/types/checkDetail/service';
import { CheckDetailService } from '@services/checkDetail/check-detail.service';
import { DataToIncludeFormComponent } from './../../../components/data-to-include-form/data-to-include-form.component';
import { SelectFiltersFormComponent } from '@modules/reporting/components';
import { ICheck, IResponse } from '@modules/management/pages/details/check/models';

import * as _ from 'lodash';
import { fromEvent, interval } from 'rxjs';
import { debounce, map } from 'rxjs/operators';

export interface IGroupItem extends ICheck {
  id: string,
  text: string,
}

export interface IGroupedList {
  text: string,
  children: IGroupItem[],
}

@Injectable()
export class checkDetailReport extends ReportBaseClass {

  public dataToIncludeFields: any[] = [];
  public reportType = 'checkDetail';

  private checkDetailService: CheckDetailService = this.injector.get(CheckDetailService);
  private checkDetailModelService: CheckDetailModelService = this.injector.get(CheckDetailModelService);
  public reportColumnOptions = this.checkDetailModelService.getColumns();
  public reportFieldOptions = this.checkDetailModelService.getFieldOptions();
  private subscriber: SubscriberService = this.injector.get(SubscriberService);

  filtersFields = [
    this.baseFields[BaseField.CheckStatus],
    this.baseFields[BaseField.CheckResults],
    this.baseFields[BaseField.CheckType],
    this.baseFields[BaseField.DeploymentType],
    this.baseFields[BaseField.DeploymentName],
    this.baseFields[BaseField.TargetLocations],
    this.baseFields[BaseField.TargetZones],
    this.baseFields[BaseField.TargetAssets],
    this.baseFields[BaseField.TargetTeams],
    this.baseFields[BaseField.TargetPermissions],
    this.baseFields[BaseField.TargetUsers],
    this.baseFields[BaseField.Users],
    this.baseFields[BaseField.Responders],
    this.baseFields[BaseField.Permissions],
    this.baseFields[BaseField.Groups],
    this.baseFields[BaseField.Assigned],
    this.baseFields[BaseField.AssignedTeam],
    this.baseFields[BaseField.AssignedPerms],
    {
      containerClass: 'report-field check checkDetail',
      title: this.translate.instant('SHARED.Detail_Tags'),
      name: 'detailTags',
      type: 'selectmenu',
      placeholder: this.translate.instant('REPORTING.EDIT_Any_Tag'),
      multiple: true,
      canDelete: true,
      valueProperty: 'tagID',
      options: this.settingsService.customTags.data,
      onRemoveField: (field: any) => this.onRemoveField(field),
      func: (item: any) => item.tag,
    },
    {
      containerClass: 'report-field check checkDetail',
      title: this.translate.instant('SHARED.Question_Category'),
      name: 'questionCategory',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.Any_Category'),
      multiple: true,
      canDelete: true,
      valueProperty: 'id',
      options: this.getQuestionCategory(),
      onRemoveField: (field: any) => this.onRemoveField(field),
      func: (item: any) => item.description,
    },
  ];
  public fields = [
    this.baseFields[BaseField.Timespan],
    this.baseFields[BaseField.LocationTime],
    {
      containerClass: 'custom-data-field report-field checkDetail',
      title: this.translate.instant('REPORTING.EDIT_Data_to_Include'),
      name: 'checkDetailReportColumns',
      type: 'customElement',
      required: true,
      component: DataToIncludeFormComponent,
      inputs: {
        options: _.cloneDeep(this.reportColumnOptions)
      },
      outputs: {
        onChanged: (selectedItems) => {
          const field = <FormField>_.find(this.formConfig.fields, {name: 'checkDetailReportColumns'});
          (field.componentRef as DataToIncludeFormComponent).selectedItems = selectedItems;
          this.dataToIncludeFields = selectedItems;
        }
      },
      options: _.cloneDeep(this.reportColumnOptions)
    },
    this.baseFields[BaseField.IncludingAll],
    {
      containerClass: 'report-field obsdets observation check checkDetail ccsFormSubdivider',
      title: this.translate.instant('REPORTING.EDIT_Report_Data_Selection'),
      type: 'divider',
    },
    {
      containerClass: 'custom-data-field report-field check checkDetail',
      title: this.translate.instant('SHARED.Select_Filters'),
      name: 'filtersFields',
      type: 'customElement',
      component: SelectFiltersFormComponent,
      inputs: {
        options: _.cloneDeep(this.filtersFields),
        selectedItems: []
      },
      outputs: {
        onChanged: (selectedItems) => {
          const field = <FormField>_.find(this.formConfig.fields, {name: 'filtersFields'});
          (field.componentRef as SelectFiltersFormComponent).selectedItems = selectedItems;
          this.removedFilters = _.difference(this.filters, selectedItems);
          this.filters = selectedItems;
          this.showFilters();
        }
      },
      options: _.cloneDeep(this.filtersFields)
    },
    {
      containerClass: 'report-field obsdets checkDetail',
      title: this.translate.instant('SHARED.Check_Name'),
      name: 'checkDetailName',
      type: 'selectmenu',
      multiple: false,
      required: true,
      canDelete: false,
      valueProperty: 'checkID',
      placeholder: this.translate.instant('SHARED.Choose_a_check'),
      options: this.getGroupedChecksByGroups(),
      searchable: true,
      func: (ref: any) => ref.title,
      test: (ref) => this.isFieldDisabledValid(ref),
      onRemoveField: (field: any) => this.onRemoveField(field),
      onChange: (selections) => this.setOptionsByChecks([selections])
    },
  ];
  private resps: any;
  private opts: any;

  constructor(protected injector: Injector) {
    super(injector);
  }

  public findInterval(opts?, items?) {
    return {};
  }

  public init(reportData) {
    if (reportData.checkType.length) {
      this.defineCheckVisibilityByValue(reportData.checkType);
    }
    if (reportData.checkDetailName) {
      this.setOptionsByChecks([reportData.checkDetailName]);
    }

    const checkDetailReportColumns: any = _.find(this.formConfig.fields, {name: 'checkDetailReportColumns'});
    checkDetailReportColumns.inputs.reportID = this.messageID;

    this.dataToIncludeFields = _.get(reportData, 'selectors.extraColumns') || [];

    super.init(reportData);
  }

  public async report(theTable, theChart, report, reportId?: number) {
    this.reportData = report;
    const tableDef = _.merge({}, this.tableDef, {
      onRowClicked: async (currentRow, event) => {
        const response = currentRow?.response;

        if ($(event.target).hasClass('col-link')) {
          const questionId = $(event.target).closest('.link').attr('data-question-id');
          this.openDetails(response, questionId, opts);
        } else {
          const responseID = response?.responseID;
          if (responseID) {
            this.router.navigate([`pages/dashboard/check-detail/${responseID}`], {queryParams: {skipFooter: true}})
          }
        }
      },
      initComplete: () => {
        const searchElement = $(theTable).closest('.dataTables_wrapper').find(`input[type='search']`);
        searchElement.off();

        fromEvent(searchElement, 'input')
          .pipe(
            debounce(() => interval(700)),
            map((event: any) => event?.target?.value)
          ).subscribe( async val => {
            await this.loadingService.enable(this.translate.instant('SHARED.Search'));
            $(theTable).DataTable().search(val).draw();
            await this.loadingService.disable();
            searchElement.trigger('focus');
        });
      }
    });
    const opts = _.cloneDeep(report.selectors);
    const timeByTimespan = this.getTimeByTimespan(opts.timespan, opts.startTime, opts.endTime);
    let data = [];
    opts.startTime = timeByTimespan.startTime;
    opts.endTime = timeByTimespan.endTime;
    const responses = await this.getResponses(opts);
    const columns = this.getColumns(opts.extraColumns, responses);

    data = _.map(responses, (response) => {
      const responseData = { response };

      _.each(columns, (column) => {
        responseData[column.id] = column.func([response]);
      });

      return responseData;
    });

    tableDef.data = data;
    tableDef.columns = this.preparationColumns(columns);

    if (tableDef.columns.length) {
      this.tableService.showDataTable(theTable, tableDef);
    }
    $('.report-view-has-chart').hide();
  }

  private getColumns(columns: string[], responses: IResponse[]) {
    const columnsInstances = _.map(columns, (column) => {
      return _.find(this.reportColumnOptions, { id: column });
    });
    const staticColumnsInstances = _.reject(columnsInstances, 'multipleColumn');
    const multipleColumnsInstances = _.filter(columnsInstances, 'multipleColumn');
    let targetColumns = staticColumnsInstances;

    if (multipleColumnsInstances.length) {
      const multipleColumns: any[] = this.defineMultipleColumns(multipleColumnsInstances, responses);
      targetColumns.push(...multipleColumns);
    }

    return targetColumns;
  }

  private defineMultipleColumns(columns: any[], responses: IResponse[]) {
    const targetColumns = {};

    _.each(responses, (response) => {
      _.each(columns, (column) => {
        column.multipleColumnHandler && column.multipleColumnHandler(response, targetColumns);
      });
    });

    return _.values(targetColumns);
  }

  private async getResponses(opts) {
    this.opts = _.cloneDeep(opts);
    this.resps = await this.checkResponseService.getMatchingCheckResponses(this.opts);

    return this.filterResponses(opts);
  }

  private filterResponses(opts) {
    const responses = [];

    _.each(this.resps, (ref: any) => {
      let ctime = ref.availableTime * 1000;
      if (ref.completionTime) {
        ctime = ref.completionTime * 1000;
      } else if (ref.startTime) {
        ctime = ref.startTime * 1000;
      } else if (ref.claimTime) {
        ctime = ref.claimTime * 1000;
      }

      if (ctime < opts.startTime || ctime > opts.endTime) {
        return;
      }

      if (!ref.locationID) {
        if (!_.isEmpty(ref.answers)) {
          _.each(ref.answers, data => {
            ref.locationID = data.locationID;
            return false;
          });
        }
        if (!ref.locationID) {
          const splitSig = _.split(ref.targetSignature, ':');
          if (splitSig[0] == 'loc') {
            ref.locationID = +splitSig[1];
          } else if (splitSig[0] == 'asset') {
            ref.locationID = this.assetsService.getAssetLocation(+splitSig[1]);
          } else if (splitSig[0] == 'worker') {
            ref.locationID = this.accountsService.getUserLocation(+splitSig[1]);
          }
          if (!ref.locationID) {
            ref.locationID = _.get(ref, 'targetLocations[0]') || 0;
          }
        }
      }

      if (!ref.locationID) {
        if (!_.isEmpty(ref.answers)) {
          _.each(ref.answers, data => {
            ref.locationID = data.locationID;
            return false;
          });
        }
        if (!ref.locationID) {
          ref.locationID = _.get(ref, 'targetLocations[0]') || 0;
        }
      }

      ref.zoneID = _.get(ref, 'target.zones[0]') || 0;

      if (!this.checkResponseService.checkResponse(ref, opts)) {
        return;
      }

      responses.push(ref);
    });

    return responses;
  }

  public getQuestionCategory() {
    let categoryField = [];
    const locationId = this.subscriber.locationID();
    const observationTypes: string[] = ['category', 'pi', 'quality'];
    const titleMap = {
      category: this.translate.instant('SHARED.Unsafe_Conditions'),
      pi: this.translate.instant('SHARED.Process_Improvements'),
      quality: this.translate.instant('SHARED.Quality_Issues')
    };

    _.each(observationTypes, (type) => {
      const categoriesByType: any[] = _.orderBy(this.settingsService.getSettingSync(type, locationId, true), 'messageTitle');
      categoryField = [
        ...categoryField,
        ..._.map(categoriesByType, (category) => {
          const observationType: string = type === 'category' ? 'condition' : type;
          return {
            id: `${observationType}:${category.messageID}`,
            description: `${titleMap[type]} - ${category.messageTitle}`
          };
        })
      ];
    });
    return categoryField;
  }

  public getTableItems(queryParams: any): any {
    return {};
  }

  public preparationColumns(columns) {
    return _.map(columns, (column) => {
      const columnConfig: any = {
        title: `${column.label} <span class="sort-icon"></span>`,
        exportTitle: `${column.label}`,
        data: column.fromID || column.id,
        render: (data, type, row) => {
          if (_.includes(['sort', 'export'], type)) {
            return column.func([row.response], type) || data;
          }
          return data;
        }
      };

      return columnConfig;
    });
  }

  public async openDetails(response: IResponse, questionId: string, opts) {
    const answerElement = response?.answers?.[questionId];

    if (_.get(answerElement, 'observationID')) {
      // ca
      const observation = await this.observationService.getObservation(_.get(answerElement, 'observationID'));
      const queryParam = {
        superTaskBoard: false,
        headerIsHidden: true
      };
      const dataSet = _.map(this.checkDetailService.currentTableData, 'observationID');
      this.observationDetailService.navigateToDetail(observation, dataSet, false, false, queryParam);
    } else if (_.get(answerElement, 'flaggedIssue') || answerElement?.result) {
      let routerParameters = [`pages/dashboard/issue-detail/${response.responseID}/${_.get(answerElement, 'flaggedIssue')}`];

      if (!answerElement?.flaggedIssue && answerElement?.result) {
        routerParameters = [`pages/dashboard/view-validation/${response.responseID}/${questionId}`];
      }

      this.router.navigate(routerParameters, {
        queryParams: {
          backNavigation: true,
          footerIsHidden: true
        }
      });
    }
  }

  private getGroupedChecksByGroups() {
    const groupedChecksByGroups: IGroupedList[] = [];
    const checkTypes = this.settingsService.getSettingSync('checkType');

    _.each(checkTypes, (checkType) => {
      const checksByType = _.values(this.checksService.getChecksByType([checkType.messageID]));

      if (checksByType.length) {
        const checkListItem = {
          text: checkType.messageTitle,
          children: _.map(checksByType, (checkItem: ICheck) => ({
            ...checkItem,
            id: _.toString(checkItem.checkID),
            text: checkItem.title,
          }))
        };
        if (checkListItem.children.length) {
          groupedChecksByGroups.push(checkListItem);
        }
      }
    });

    return groupedChecksByGroups;
  }

}
