import { ElementRef, Injector } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';

import {
  AccountsService,
  AssetsService,
  CheckResponseService,
  ChecksService,
  FoldersDataService,
  FormBuilderService,
  FormField,
  LoadingService,
  ObservationService,
  PermissionsService,
  RolesService,
  SettingsService,
  ShiftService,
  SubscriberService,
  TableColumn,
  TableService,
  TeamsService,
  UserdataService,
  UserService,
  UtilsService,
  ZoneService
} from '@services';

import { GraphService } from '@modules/reporting/services/graph.service';
import { DeploymentService } from '@modules/management/pages/details/check/services';
import { ReportService } from '@modules/reporting/services/report.service';

import * as _ from 'lodash';
import * as Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { TranslateService } from '@ngx-translate/core';
import {ObservationDetailService} from '@services/observationDetail/observation-detail.service';
import { FolderDataType } from '@modules/management/modules/folders/services/folders.service';
import {
  FolderPickerComponent
} from '@modules/management/modules/folder-picker/components/folder-picker/folder-picker.component';
import { filter, find, map } from 'lodash';

export enum BaseField {
  Timespan = 'timespan',
  LocationTime = 'useLocationTime',
  IncludingAll = 'IncludingAll',
  ShowDataLabels = 'ShowDataLabels',
  Groups = 'Groups',
  Period = 'Period',
  GraphType = 'GraphType',
  Locations = 'Locations',
  Permissions = 'Permissions',
  CheckStatus = 'CheckStatus',
  CheckResults = 'CheckResults',
  CheckType = 'CheckType',
  DeploymentType = 'DeploymentType',
  DeploymentName = 'DeploymentName',
  TargetLocations = 'TargetLocations',
  TargetZones = 'TargetZones',
  TargetAssets = 'TargetAssets',
  TargetTeams = 'TargetTeams',
  TargetPermissions = 'TargetPermissions',
  TargetUsers = 'TargetUsers',
  Users = 'Users',
  Assigned = 'Assigned',
  AssignedTeam = 'AssignedTeam',
  AssignedPerms = 'AssignedPerms',
  Responders = 'Responders'
}

export abstract class ReportBaseClass {
  abstract fields;
  abstract filtersFields;
  abstract reportColumnOptions;
  abstract reportFieldOptions;
  abstract reportType;
  public reportFieldOptions_temporarily?;
  public translate: TranslateService = this.injector.get(TranslateService);
  public utils: UtilsService = this.injector.get(UtilsService);
  public teamsService: TeamsService = this.injector.get(TeamsService);
  public userService: UserService = this.injector.get(UserService);
  public observations: ObservationService = this.injector.get(ObservationService);
  public settingsService: SettingsService = this.injector.get(SettingsService);
  public userdataService: UserdataService = this.injector.get(UserdataService);
  public zoneService: ZoneService = this.injector.get(ZoneService);
  public shift: ShiftService = this.injector.get(ShiftService);
  public accountsService: AccountsService = this.injector.get(AccountsService);
  public permissionsService: PermissionsService = this.injector.get(PermissionsService);
  public router: Router = this.injector.get(Router);
  public graphService: GraphService = this.injector.get(GraphService);
  public tableService: TableService = this.injector.get(TableService);
  public formBuilderService: FormBuilderService = this.injector.get(FormBuilderService);
  public checksService: ChecksService = this.injector.get(ChecksService);
  public assetsService: AssetsService = this.injector.get(AssetsService);
  public deploymentService: DeploymentService = this.injector.get(DeploymentService);
  public checkResponseService: CheckResponseService = this.injector.get(CheckResponseService);
  public reportService: ReportService = this.injector.get(ReportService);
  public popover: PopoverController = this.injector.get(PopoverController);
  public loadingService: LoadingService = this.injector.get(LoadingService);
  public subscriberService: SubscriberService = this.injector.get(SubscriberService);
  public rolesService: RolesService = this.injector.get(RolesService);
  public observationService: ObservationService = this.injector.get(ObservationService);
  public observationDetailService: ObservationDetailService = this.injector.get(ObservationDetailService);
  public foldersDataService: FoldersDataService = this.injector.get(FoldersDataService);
  public accounts = this.accountsService.getUserlist(this.userdataService.locations);
  public teams = this.teamsService.getTeamList(this.userdataService.locations);
  public locations = this.userService.getUserLocations(this.userdataService.locations);
  public zones = this.zoneService.getGroupedZonesByLocations(this.userdataService.locations);
  public formConfig;
  public formPrefixId: string;
  public customDateRange: any = this.reportService.customDateRange;
  public messageID: string;
  public nativeElement: any;
  public elementRef: ElementRef = new ElementRef(document);
  public filters: any[] = [];
  public removedFilters: any[] = [];
  public initFilters: any[] = [];
  currentReportChart: any = null;
  public reportData;
  public tableId: string;
  public excelBtnConfig = {
    buttons: [
      {
        name: 'excel',
        extend: 'excel',
        className: 'hidden'
      },
      {
        name: 'pdf',
        extend: 'pdf',
        className: 'hidden',
        customize: (doc) => {
          const tableWidthInPixels = $(`#${this.tableId}`).width();
          const tablePoints = tableWidthInPixels / 96 * 72; // Pixels to Points
          const maxPoints = 10000;
          const points = tablePoints > maxPoints ? maxPoints : tablePoints;
          doc.pageSize = {
            width: points,
            height: points * 1.414 // aspect ratio A-format
          };
        },
        title: () => this.reportData?.name,
      }
    ]
  };
  public tableDef = {
    rowprop: 'data-mid',
    colgroups: {},
    columns: [],
    groupBy: 0,
    data: [],
    disableAutoResizeHandler: true,
    deferRender: true,
    scrollX: true,
    paging: true,
    scrollY: '600px',
    scroller: {
      loadingIndicator: true,
      rowHeight: 60
    },
    buttons: this.excelBtnConfig,
    skipCustomSortProvider: true
  };
  public baseFields = {
    [BaseField.LocationTime]: {
      containerClass: 'report-field obsdets check checkDetail observation PPESummary team worker zone',
      title: this.translate.instant('REPORTING.EDIT_Use_Locations_Time'),
      name: 'locationTime',
      type: 'flipswitch',
      onText: this.translate.instant('SHARED.Yes'),
      offText: this.translate.instant('SHARED.No'),
      value: 1,
      default: 0
    },
    [BaseField.Timespan]: {
      containerClass: 'report-field obsdets check checkDetail observation PPESummary team worker zone',
      title: this.translate.instant('SHARED.Timespan'),
      name: 'timespan',
      type: 'dateRangePicker',
      required: true,
      multiple: false,
      options: this.reportService.timespans,
      onChange: (value: any) => {
        const id = `#${this.formConfig.prefix}period`;

        if (value.type === 'custom') {
          _.assign(this.reportService.customDateRange, value.range);
        }

        this.syncPeriodValues(id, value.type);
      },
      originalOrder: true,
      default: '30days'
    },
    [BaseField.IncludingAll]: {
      containerClass: 'report-field obsdets check checkDetail observation PPESummary observationType conditionObservation qualityObservation piObservation ',
      title: this.translate.instant('REPORTING.EDIT_Include_Zero'),
      name: 'includingAll',
      type: 'checkbox',
      role: 'none',
      class: 'inline-checkboxes',
      multiple: false,
      options: [
        {
          id: 'include',
          description: this.translate.instant('REPORTING.EDIT_All_Results')
        }
      ]
    },
    [BaseField.ShowDataLabels]: {
      containerClass: 'report-field obsdets check checkDetail observation PPESummary observationType conditionObservation qualityObservation piObservation ',
      title: this.translate.instant('REPORTING.EDIT_Show_Data_Labels'),
      name: 'showDataLabels',
      type: 'checkbox',
      role: 'none',
      class: 'inline-checkboxes',
      multiple: false,
      options: [
        {
          id: 'show',
          description: this.translate.instant('REPORTING.EDIT_Data_Labels')
        }
      ]
    },
    [BaseField.Groups]: {
      containerClass: 'report-field obsdets observation check checkDetail PPESummary',
      title: this.translate.instant('REPORTING.EDIT_Owner_Teams'),
      name: 'groups',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.Any_Team'),
      multiple: true,
      canDelete: true,
      valueProperty: 'groupID',
      options: this.getTeamOptions(),
      func: (ref: any) => ref.name,
      test: (ref) => this.isFieldDisabledValid(ref),
      onRemoveField: (field: any) => this.onRemoveField(field),
      originalOrder: true
    },
    [BaseField.Locations]: {
      containerClass: 'report-field obsdets team worker zone observation PPESummary',
      title: this.translate.instant('SHARED.Locations'),
      name: 'locations',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.All_Locations'),
      multiple: true,
      canDelete: true,
      valueProperty: 'locationID',
      options: this.locations,
      func: (ref: any) => ref.name,
      test: (ref) => this.isFieldDisabledValid(ref),
      onRemoveField: (field: any) => this.onRemoveField(field),
      onChange: (locationIds) => this.syncFieldsBySelectedLocations(_.map(locationIds, Number))
    },
    [BaseField.Period]: {
      containerClass: 'report-field obsdets check observation team worker zone PPESummary',
      title: this.translate.instant('SHARED.Period'),
      name: 'period',
      type: 'selectmenu',
      required: true,
      multiple: false,
      options: this.reportService.buildPeriodList('30days'),
      originalOrder: true,
      onChange: () => this.updateGraphFields(),
    },
    [BaseField.GraphType]: {
      containerClass: 'report-field check obsdets observation worker team zone PPESummary',
      title: this.translate.instant('REPORTING.EDIT_Type_of_Graph'),
      name: 'graphType',
      type: 'selectmenu',
      multiple: false,
      required: true,
      originalOrder: true,
      options: [
        {id: 'none', description: this.translate.instant('SHARED.None')},
        {id: 'pareto', description: this.translate.instant('REPORTING.EDIT_Pareto')},
        {id: 'pie', description: this.translate.instant('REPORTING.EDIT_Pie_Chart')},
        {id: 'line', description: this.translate.instant('REPORTING.EDIT_Line_Chart')},
        {id: 'lineNoFill', description: this.translate.instant('REPORTING.EDIT_Line_Chart_No_Fill')},
        {id: 'bar', description: this.translate.instant('REPORTING.EDIT_Bar_Graph')},
        {id: 'stacked', description: this.translate.instant('REPORTING.EDIT_stacked')},
        {id: 'hbar', description: this.translate.instant('REPORTING.EDIT_hbar')},
        {id: 'hstacked', description: this.translate.instant('REPORTING.EDIT_hstacked')}
      ]
    },

    [BaseField.Permissions]: {
      containerClass: 'report-field team zone check checkDetail',
      title: this.translate.instant('SHARED.EDIT_Permissions'),
      name: 'permissions',
      type: 'selectmenu',
      multiple: true,
      canDelete: true,
      placeholder: this.translate.instant('SHARED.EDIT_Any_Permissions'),
      valueProperty: 'id',
      options: this.permissionsService.permissions.data,
      test: (ref) => this.filterPermission(ref),
      onRemoveField: (field: any) => this.onRemoveField(field)
    },
    [BaseField.CheckStatus]: {
      containerClass: 'report-field obsdets check checkDetail checkType',
      title: this.translate.instant('SHARED.Check_Status'),
      name: 'checkStatus',
      type: 'checkbox',
      role: 'none',
      class: 'inline-checkboxes',
      multiple: true,
      canDelete: true,
      placeholder: 'Any Status',
      options: [
        {id: 'available', description: this.translate.instant('SHARED.Available')},
        {id: 'complete', description: this.translate.instant('SHARED.Completed')},
        {id: 'notCompleted', description: this.translate.instant('SHARED.NotCompleted')},
        {id: 'skipped', description: this.translate.instant('SHARED.Skipped')},
        {id: 'missed', description: this.translate.instant('SHARED.Missed')},
        {id: 'groupsCompleted', description: this.translate.instant('SHARED.OptionalGroups')}
      ],
      onRemoveField: (field: any) => this.onRemoveField(field)
    },
    [BaseField.CheckResults]: {
      containerClass: 'report-field obsdets check checkDetail checkType',
      title: this.translate.instant('SHARED.Check_Results'),
      name: 'checkResults',
      type: 'checkbox',
      role: 'none',
      class: 'inline-checkboxes',
      multiple: true,
      canDelete: true,
      placeholder: this.translate.instant('SHARED.Any_Result'),
      options: [
        {id: 'positive', description: this.translate.instant('SHARED.Positive')},
        {id: 'negative', description: this.translate.instant('SHARED.Negative')},
        {id: 'neutral', description: this.translate.instant('SHARED.Neutral')},
        {id: 'none', description: this.translate.instant('SHARED.None')}
      ],
      onRemoveField: (field: any) => this.onRemoveField(field)
    },
    [BaseField.CheckType]: {
      containerClass: 'report-field obsdets check checkDetail observationType complimentObservation',
      title: this.translate.instant('SHARED.Check_Type'),
      name: 'checkType',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.Any_Type'),
      multiple: true,
      canDelete: true,
      valueProperty: 'messageID',
      options: this.settingsService.getSettingSync('checkType', this.userdataService.locations),
      func: (ref: any) => ref.messageTitle,
      test: (ref) => this.isFieldDisabledValid(ref),
      onChange: (selections) => this.defineCheckVisibilityByValue(selections),
      onRemoveField: (field: any) => this.onRemoveField(field)
    },
    [BaseField.DeploymentType]: {
      containerClass: 'report-field check checkDetail',
      title: this.translate.instant('SHARED.Deployment_Type'),
      name: 'deploymentType',
      type: 'selectmenu',
      role: 'none',
      multiple: true,
      canDelete: true,
      placeholder: this.translate.instant('SHARED.Any_Type'),
      options: [
        {id: 'assets', description: this.translate.instant('SHARED.ASSETS')},
        {id: 'zones', description: this.translate.instant('SHARED.Zones')},
        {id: 'workers', description: this.translate.instant('SHARED.Workers')}
      ],
      onChange: (selections) => this.defineResponseTypeVisibilityByValue(selections),
      onRemoveField: (field: any) => this.onRemoveField(field)
    },
    [BaseField.DeploymentName]: {
      containerClass: 'report-field check checkDetail',
      title: this.translate.instant('SHARED.Deployment_Name'),
      name: 'deploymentName',
      type: 'selectmenu',
      role: 'none',
      multiple: true,
      canDelete: true,
      placeholder: this.translate.instant('REPORTING.Any_Deployment'),
      options: this.deploymentService.getGroupedDeploymentsByChecks(this.checksService.checks.data),
      func: (ref: any) => ref.title,
      test: (ref) => this.isFieldDisabledValid(ref),
      onRemoveField: (field: any) => this.onRemoveField(field)
    },
    [BaseField.TargetLocations]: {
      containerClass: 'report-field check checkDetail',
      title: this.translate.instant('SHARED.Target_Locations'),
      name: 'targetLocations',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.All_Locations'),
      multiple: true,
      canDelete: true,
      valueProperty: 'locationID',
      options: this.locations,
      func: (ref: any) => ref.name,
      test: (ref) => this.isFieldDisabledValid(ref),
      onChange: (locationIds) => this.syncFieldsBySelectedTargetLocations(_.map(locationIds, Number)),
      onRemoveField: (field: any) => this.onRemoveField(field)
    },
    [BaseField.TargetZones]: {
      containerClass: 'report-field check checkDetail',
      title: this.translate.instant('SHARED.Target_Zones'),
      name: 'targetZones',
      type: 'selectmenu',
      multiple: true,
      canDelete: true,
      placeholder: this.translate.instant('SHARED.Any_Zone'),
      options: this.zones,
      func: (ref) => this.showDeactivated(ref.id, ref),
      onRemoveField: (field: any) => this.onRemoveField(field)
    },
    [BaseField.TargetAssets]: {
      containerClass: 'report-field check checkDetail custom-data-field removable',
      title: this.translate.instant('SHARED.Assets'),
      name: 'targetAssets',
      placeholder: this.translate.instant('SHARED.Any_Type'),
      type: 'customElement',
      component: FolderPickerComponent,
      inputs: {
        type: FolderDataType.ASSET,
        getSourceItems: () => {
          const location = find(this.filtersFields, {name: 'targetLocations'}) as FormField;
          let locationIds: number[] = [];

          if (location?.getValue) {
            locationIds = filter(map(location.getValue(), Number));
          }

          return this.getAssetsByCurrentLocations(locationIds);
        }
      },
      canDelete: true,
      func: (ref: any) => ref.title,
      test: (ref) => this.isFieldDisabledValid(ref),
      onRemoveField: (field: any) => this.onRemoveField(field)
    },
    [BaseField.TargetTeams]: {
      containerClass: 'report-field check checkDetail',
      title: this.translate.instant('SHARED.Target_Teams'),
      name: 'targetTeams',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.Any_Team'),
      multiple: true,
      canDelete: true,
      valueProperty: 'groupID',
      options: this.getTeamOptions(),
      func: (ref: any) => ref.name,
      test: (ref) => this.isFieldDisabledValid(ref),
      originalOrder: true,
      onRemoveField: (field: any) => this.onRemoveField(field)
    },
    [BaseField.TargetPermissions]: {
      containerClass: 'report-field check checkDetail',
      title: this.translate.instant('SHARED.Target_Permissions'),
      name: 'targetPermissions',
      type: 'selectmenu',
      multiple: true,
      canDelete: true,
      placeholder: this.translate.instant('SHARED.EDIT_Any_Permissions'),
      valueProperty: 'id',
      options: this.permissionsService.permissions.data,
      test: (ref) => this.filterPermission(ref),
      onRemoveField: (field: any) => this.onRemoveField(field)
    },
    [BaseField.TargetUsers]: {
      containerClass: 'report-field check checkDetail',
      title: this.translate.instant('SHARED.Target_Users'),
      name: 'targetUsers',
      type: 'selectmenu',
      multiple: true,
      canDelete: true,
      placeholder: this.translate.instant('SHARED.EDIT_Any_Worker'),
      valueProperty: 'userID',
      options: this.accounts,
      func: (ref: any) => this.userService.getFullname(ref.userID),
      test: (ref) => this.isFieldDisabledValid(ref),
      onRemoveField: (field: any) => this.onRemoveField(field)
    },
    [BaseField.Responders]: {
      containerClass: 'report-field check checkDetail',
      title: this.translate.instant('REPORTING.EDIT_Responders'),
      name: 'responders',
      type: 'selectmenu',
      multiple: true,
      canDelete: true,
      placeholder: this.translate.instant('REPORTING.EDIT_Any_Responder'),
      valueProperty: 'userID',
      options: this.accounts,
      func: (ref: any) => this.userService.getFullname(ref.userID),
      test: (ref) => this.isFieldDisabledValid(ref),
      onRemoveField: (field: any) => this.onRemoveField(field)
    },
    [BaseField.Users]: {
      containerClass: 'report-field team worker zone check checkDetail',
      title: this.translate.instant('REPORTING.EDIT_Workers'),
      name: 'users',
      type: 'selectmenu',
      multiple: true,
      canDelete: true,
      placeholder: this.translate.instant('SHARED.EDIT_Any_Worker'),
      valueProperty: 'userID',
      options: this.getWorkerOptions(),
      func: (ref: any) => this.userService.getFullname(ref.userID),
      test: (ref) => this.isFieldDisabledValid(ref),
      onRemoveField: (field: any) => this.onRemoveField(field)
    },
    [BaseField.Assigned]: {
      containerClass: 'report-field obsdets check checkDetail',
      title: this.translate.instant('SHARED.Assignee'),
      name: 'assigned',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.EDIT_Any_Worker'),
      multiple: true,
      canDelete: true,
      valueProperty: 'userID',
      options: this.getUsersOptions(),
      func: (ref: any) => {
        let userName: string;
        if (ref.userID === -1 || ref.userID === -2) {
          userName = ref.name;
        } else {
          userName = this.userService.getFullname(ref.userID);
        }
        return userName;
      },
      test: (ref) => this.isFieldDisabledValid(ref),
      onRemoveField: (field: any) => this.onRemoveField(field),
      originalOrder: true
    },
    [BaseField.AssignedTeam]: {
      containerClass: 'report-field obsdets check checkDetail',
      title: this.translate.instant('SHARED.Assignee_Team'),
      name: 'assignedTeam',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.Any_Team'),
      multiple: true,
      canDelete: true,
      valueProperty: 'groupID',
      options: this.getTeamOptions(),
      func: (ref: any) => ref.name,
      test: (ref) => this.isFieldDisabledValid(ref),
      onRemoveField: (field: any) => this.onRemoveField(field),
      originalOrder: true
    },
    [BaseField.AssignedPerms]: {
      containerClass: 'report-field obsdets check checkDetail',
      title: this.translate.instant('SHARED.Assignee_Perms'),
      name: 'assignedPerms',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.EDIT_Any_Permissions'),
      valueProperty: 'id',
      multiple: true,
      canDelete: true,
      options: this.permissionsService.permissions.data,
      test: (ref) => this.filterPermission(ref),
      onRemoveField: (field: any) => this.onRemoveField(field)
    }
  };

  constructor(protected injector: Injector) {
  }

  abstract findInterval(opts?, items?);

  abstract report(theTable, theChart, report, reportId?: number, isExport?: boolean);

  abstract getTableItems(queryParams: any);

  public init(reportData) {
    const locationsIDs: number[] = _.get(reportData, 'locations.length') ? reportData.locations : this.userdataService.locations;
    // this.filters = _.get(reportData, 'selectors.filtersFields.length') ? reportData.selectors.filtersFields : [];
    this.syncFieldsBySelectedLocations(locationsIDs);
    this.updateGraphFields();
    // this.showFilters();
  }

  public newReport(theTable, theChart, report, reportId?: number) {}

  public getPrimary(opts, sRef, intervals) {
    const primary = {};
    let options = [];
    let selectorType: string;
    let filterName: string;
    const selectors = this.setRowsOptions(opts.primary, opts.reportType, opts.locations);
    if (selectors) {
      options = _.get(selectors, 'options');
      selectorType = _.get(selectors, 'selectorType');
      filterName = _.get(selectors, 'filterName');
      _.forEach(options, (typeItem) => {
        let theID = typeItem.id;
        if (filterName && _.has(opts, filterName) && opts[filterName].length) {
          if (!_.includes(opts[filterName], theID)) {
            return;
          }
        }
        if (_.has(typeItem, 'subtype')) {
          theID += ':' + typeItem.subtype;
        }
        primary[theID] = {
          extras: {},
          fieldName: selectorType,
          intervals: {},
          items: [],
          label: typeItem.description,
          totalObservations: 0,
          totalcount: 0,
          itemID: typeItem.id,
          subtype: typeItem.subtype
        };
        primary[theID][selectorType] = typeItem.description;
        if (sRef) {
          let sOptions = [];
          let sSelectorType: string;
          let sFilterName: string;
          const secondarySelectors = this.setRowsOptions(opts.secondary, opts.reportType, opts.locations);
          primary[theID].secondary = {};
          if (secondarySelectors) {
            sOptions = _.get(secondarySelectors, 'options');
            sSelectorType = _.get(secondarySelectors, 'selectorType');
            sFilterName = _.get(secondarySelectors, 'filterName');
            const secondaryRowName = `secondary_${sSelectorType}`;
            _.forEach(sOptions, (sTypeItem) => {
              let theSID = sTypeItem.id;
              if (sFilterName && _.has(opts, sFilterName) && opts[sFilterName].length) {
                if (!_.includes(opts[sFilterName], theSID)) {
                  return;
                }
              }
              if (_.has(sTypeItem, 'subtype')) {
                theSID += ':' + sTypeItem.subtype;
              }
              primary[theID].secondary[theSID] = {
                extras: {},
                fieldName: selectorType,
                intervals: {},
                items: [],
                label: sTypeItem.description,
                totalObservations: 0,
                totalcount: 0,
                secondFieldName: sSelectorType,
                itemID: sTypeItem.id,
                subtype: sTypeItem.subtype
              };
              primary[theID].secondary[theSID][selectorType] = typeItem.description;
              primary[theID].secondary[theSID][secondaryRowName] = sTypeItem.description;
              if (opts.interval !== 'none') {
                _.each(intervals, (iref, counter) => {
                  const iname = 'p' + counter;
                  primary[theID].secondary[theSID].intervals[iname] = {extras: {}, items: []};
                });
              }
            });
          }
        } else {
          if (opts.interval !== 'none') {
            _.each(intervals, (iref, counter) => {
              const iname = 'p' + counter;
              primary[typeItem.id].intervals[iname] = {extras: {}, items: []};
            });
          }
        }
      });
      return primary;
    }
  }

  public setRowsOptions(opt, reportType, locations) {
    let options = [];
    let selectorType: string;
    let filterName: string;
    const locs = locations?.length ? locations : this.userdataService.locations;
    if (opt === 'state' && reportType === 'observation') {
      selectorType = 'state';
      options = [
        {
          id: 'open',
          description: this.translate.instant('SHARED.Open')
        },
        {
          id: 'unassigned',
          description: this.translate.instant('SHARED.Unassigned')
        },
        {
          id: 'fixed',
          description: this.translate.instant('SHARED.Fixed')
        },
        {
          id: 'closed',
          description: this.translate.instant('SHARED.Closed')
        }
      ];
    } else if (opt === 'creatorRole') {
      selectorType = 'userID';
      const roles = this.rolesService.roles.data;
      _.forEach(roles, (item) => {
        options.push({
          id: item.roleID,
          description: item.name
        });
      });
    } else if (opt === 'closer' || opt === 'creator' || opt === 'fixer' || opt === 'owner') {
      selectorType = opt === 'owner' ? 'ownerID' : 'userID';
      const rejectUserTypes: string[] = ['observation', 'reporting'];
      const accounts = _.filter(this.accountsService.getUserlist(locs), (user) => !user.disabledAt && user.active && !_.includes(rejectUserTypes, user.type));
      _.forEach(accounts, (item) => {
        options.push({
          id: item.userID,
          description: this.accountsService.fullname(item.userID)
        });
      });
    } else if (opt === 'tag') {
      selectorType = 'tags';
      const tags = this.settingsService.customTags.data;
      _.forEach(tags, (item) => {
        options.push({
          id: item.tagID,
          description: item.tag
        });
      });
    } else if (opt === 'team' || opt === 'creatorTeam' || opt === 'fixerTeam' || opt === 'ownerTeam') {
      if (opt === 'team') {
        selectorType = 'groupID';
      } else if (opt === 'ownerTeam') {
        selectorType = 'ownerID';
      } else {
        selectorType = 'userID';
      }
      const teams = this.teamsService.getTeamList(locs);
      _.forEach(teams, (item) => {
        options.push({
          id: item.groupID,
          description: item.name
        });
      });
    } else if (opt === 'locationID') {
      selectorType = 'locationID';
      filterName = 'locations';
      const locations = this.userService.getUserLocations(locs);
      _.forEach(locations, (item) => {
        options.push({
          id: item.locationID,
          description: item.name
        });
      });
    } else if (opt === 'zone') {
      selectorType = 'zoneID';
      const zones = this.zoneService.getGroupedZonesByLocations(locs);
      _.forEach(zones, (item) => {
        _.forEach(item.children, (zone) => {
          options.push({
            id: zone.id,
            description: item.text + ': ' + zone.text
          });
        });
      });
    } else if (opt === 'categories') {
      selectorType = 'categories';
      const categories = Object.assign({},
        this.settingsService.getSettingSync('category', locs),
        this.settingsService.getSettingSync('behavior', locs),
        this.settingsService.getSettingSync('mitigation', locs),
        this.settingsService.getSettingSync('quality', locs),
        this.settingsService.getSettingSync('compliment', locs)
      );
      _.forEach(categories, (item) => {
        options.push({
          id: item.messageID,
          description: item.messageTitle
        });
      });
    } else if (opt === 'creator_shift' || opt === 'helper_shift') {
      selectorType = opt === 'creator_shift' ? 'creatorShift' : 'ownerShift';
      const accounts = this.accountsService.getUserlist(locs);
      _.forEach(accounts, (item) => {
        options.push({
          id: item.shift,
          description: this.shift.name(item.shift)
        });
      });
    } else if (opt === 'type') {
      selectorType = 'type';
      filterName = 'obstype';
      options = [
        {
          id: 'condition',
          description: this.translate.instant('SHARED.Unsafe_Condition')
        },
        {
          id: 'behavior',
          description: this.translate.instant('SHARED.Coaching_Opportunity')
        },
        {
          id: 'quality',
          description: this.translate.instant('SHARED.Quality')
        },
        {
          id: 'pi:waiting',
          description: this.translate.instant('SHARED.Waiting')
        },
        {
          id: 'pi:general',
          description: this.translate.instant('SHARED.General_Improvement')
        },
        {
          id: 'compliment',
          description: this.translate.instant('SHARED.Thumbs-Up')
        },
        {
          id: 'ca',
          description: this.translate.instant('SHARED.Corrective_Action')
        },
        {
          id: 'si',
          description: this.translate.instant('SHARED.Opportunity')
        }
      ];
    } else if (opt === 'state' && reportType !== 'observation' && reportType !== 'PPESummary') {
      selectorType = 'instanceStatus';
      options = [
        {
          id: 'groupsCompleted',
          description: this.translate.instant('SHARED.OptionalGroups')
        },
        {
          id: 'complete',
          description: this.translate.instant('SHARED.Completed')
        },
        {
          id: 'available',
          description: this.translate.instant('SHARED.Available')
        },
        {
          id: 'missed',
          description: this.translate.instant('SHARED.Missed')
        },
        {
          id: 'skipped',
          description: this.translate.instant('SHARED.Skipped')
        },
        {
          id: 'notCompleted',
          description: this.translate.instant('SHARED.NotCompleted')
        }
      ];
    }
    return {options, selectorType, filterName};
  }

  public getTimeByTimespan(timespan: string, startTime, endTime): { startTime: number; endTime: number } {
    const timeObject = {startTime, endTime};

    if (timespan && timespan !== 'custom') {
      const timeByTimespan = this.utils.timespan(timespan);

      if (timeByTimespan) {
        timeObject.startTime = timeByTimespan.startTime;
        timeObject.endTime = timeByTimespan.endTime;
      }
    } else if (timespan === 'custom') {
      timeObject.startTime = this.customDateRange.startTime;
      timeObject.endTime = this.customDateRange.endTime;
    }

    return timeObject;
  }

  public filterCategories(data, opts) {
    let orders = data;
    const ids = [];
    if (_.indexOf(opts.obstype, 'compliment', 1) && opts.compliments && opts.compliments.length) {
      ids.push(...opts.compliments);
    }
    if (_.indexOf(opts.obstype, 'quality', 1) && opts.qualitycats && opts.qualitycats.length) {
      ids.push(...this.filterCategoryIds(orders, opts.qualitycats));
    }
    if (_.indexOf(opts.obstype, 'pi', 1) && opts.picats && opts.picats.length) {
      ids.push(...this.filterCategoryIds(orders, opts.picats));
    }
    if (_.indexOf(opts.obstype, 'condition', 1) && opts.categories && opts.categories.length) {
      ids.push(...this.filterCategoryIds(orders, opts.categories));
    }
    if (_.indexOf(opts.obstype, 'behavior', 1) && opts.behaviors && opts.behaviors.length) {
      ids.push(...opts.behaviors);
    }
    if (opts.primary === 'tag' && opts.tags && opts.tags.length) {
      ids.push(...this.filterCategoryIds(orders, opts.tags, 'tags'));
    }
    if (opts.mitigations && opts.mitigations.length) {
      ids.push(...this.filterCategoryIds(orders, opts.mitigations));
    }
    if (ids.length) {
      orders = _.pick(data, ids);
    } else if (ids.length && opts.secondary === 'categories') {
      _.forEach(orders, (order) => {
        order.secondary = _.pick(order.secondary, ids);
      });
    }
    return orders;
  }

  public filterCategoryIds(targetList, filterList: number[], key: string = 'categories'): number[] {
    const ids: number[] = [];

    _.each(targetList, (targetItem, targetId) => {
      const isValid = _.some(targetItem.items, (item) => _.intersection(item[key], filterList).length > 0);

      if (isValid) {
        ids.push(+targetId);
      }
    });

    return ids;
  }

  public drawReportGraph(theTarget, data, opts, reportId: number) {

    const pRef = $(theTarget).parent();
    const n = data.labels.length * 20;

    if (n < 400) {
      pRef.attr('style', 'height: 400px');
    } else {
      pRef.attr('style', 'height: ' + n + 'px');
    }

    const plugins = opts.showDataLabels ? [ChartDataLabels] : [];

    let chartType;

    const chartOpts: any = {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: true,
        fontSize: 24,
        fontStyle: 'regular',
        fontFamily: 'WorkSans-SemiBold, SourceSansPro-SemiBold, NotoColorEmoji-Regular',
        text: opts.name
      },
      legend: {usePointStyle: true, display: true, position: 'bottom'},
      plugins: {
        datalabels: <any>{
          backgroundColor(context) {
            return context.dataset.backgroundColor;
          },
          borderRadius: 4,
          color: 'white',
          font: {
            weight: 'bold'
          },
          // display: 'auto',
          formatter(value) {
            return value > 0 ? value : '';
          },
          padding(value) {
            return value.dataset.data[value.dataIndex] > 0 ? 6 : 0;
          },
          align: 'center',
          anchor: 'center'
        }
      }
    };
    chartOpts.tooltips = {
      mode: 'index',
      position: 'nearest',
      filter(tooltipItem, data) {
        return tooltipItem.value !== 'NaN' && tooltipItem != 0;
      },
      intersect: false,
    };
    if (opts.graphPrimary !== 'period' && !opts.secondary) {
      // we basically want a legend if we are grouping by period
      chartOpts.legend = false;
    }

    // marshall the data we need
    if (opts.graphType === 'hbar') {
      chartType = 'horizontalBar';
      if (data.yaxisLabel) {
        chartOpts.scales = {
          xAxes: [{
            stacked: false,
            ticks: {min: 0, beginAtZero: true},
            scaleLabel: {display: true, labelString: data.yaxisLabel}
          }],
          yAxes: [{stacked: false, ticks: {min: 0, beginAtZero: true}}]
        };
      } else {
        chartOpts.scales = {
          yAxes: [{ticks: {min: 0, beginAtZero: true}, stacked: false,}],
        };
      }
    } else if (opts.graphType === 'hstacked') {
      chartType = 'horizontalBar';
      chartOpts.scales = {
        xAxes: [{stacked: true}],
        yAxes: [{stacked: true, ticks: {min: 0, beginAtZero: true}}],
      };
      if (data.yaxisLabel) {
        chartOpts.scales.xAxes[0].scaleLabel = {display: true, labelString: data.yaxisLabel};
      }
      chartOpts.tooltips.mode = 'point';
    } else if (opts.graphType === 'bar') {
      chartType = 'bar';
      if (data.yaxisLabel) {
        chartOpts.scales = {
          xAxes: [{stacked: false}],
          yAxes: [{
            ticks: {min: 0, beginAtZero: true},
            stacked: false,
            scaleLabel: {display: true, labelString: data.yaxisLabel}
          }],
        };
      } else {
        chartOpts.scales = {
          yAxes: [{ticks: {min: 0, beginAtZero: true}, stacked: false,}],
        };
      }
    } else if (opts.graphType === 'stacked') {
      chartType = 'bar';
      chartOpts.scales = {
        xAxes: [{stacked: true}],
        yAxes: [{stacked: true, ticks: {min: 0, beginAtZero: true}}],
      };
      if (data.yaxisLabel) {
        chartOpts.scales.yAxes[0].scaleLabel = {display: true, labelString: data.yaxisLabel};
      }
      chartOpts.tooltips.mode = 'point';
    } else if (opts.graphType === 'line') {
      chartType = 'line';
      chartOpts.legend = {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: false,
          fontFamily: 'WorkSans, SourceSansPro',
          fontSize: 14,
          boxWidth: 20,
          padding: 20
        }
      };
      chartOpts.plugins.datalabels.align = 'end';
      chartOpts.spanGaps = true;
      chartOpts.scales = {
        yAxes: [{ticks: {min: 0, beginAtZero: true}, scaleLabel: {display: true, labelString: data.yaxisLabel}}]
      };
      chartOpts.tooltips.mode = 'point';
    } else if (opts.graphType === 'pie') {
      chartType = 'pie';
      // plugins = [ChartDataLabels];
      chartOpts.legend = {
        usePointStyle: true,
        display: true,
        position: 'bottom',
        labels: {
          fontFamily: 'WorkSans, SourceSansPro',
          fontSize: 14,
          boxWidth: 20,
          padding: 20
        }
      };
    } else if (opts.graphType === 'pareto') {
      chartType = 'bar';
      chartOpts.legend = {
        usePointStyle: true,
        display: true,
        position: 'bottom',
        labels: {
          fontFamily: 'WorkSans, SourceSansPro',
          fontSize: 14,
          boxWidth: 20,
          padding: 20,
          filter: (item) => item.text
        }
      };
      chartOpts.tooltips.mode = 'point';
      /*
        mode: 'index',
        position: 'nearest',
        filter: function (tooltipItem, data) {
          return tooltipItem.value !== 'NaN';
        },
        callbacks: {
          // Use the footer callback to display the sum of the items showing in the tooltip
          label: function (tooltipItem, data) {
            const ref = data.datasets[tooltipItem.dataSetIndex];
            let label = tooltipItem.datasetIndex === 0 ? 'Pct' : data.datasets[tooltipItem.datasetIndex].label;

            if (label) {
              label += ': ';
            }
            label += Math.round(tooltipItem.yLabel * 100) / 100;
            return label;
          },
        }
      };
        */
      chartOpts.scales = {
        /*
        xAxes: [
          {
            id: 'bottom',
            type: 'category',
            position: 'bottom',
            autoSkip: false,
            labels: []
          }
        ],*/
        xAxes: [{
          ticks: {autoSkip: false},
          stacked: true
        }
        ],
        yAxes: [{
          id: 'left',
          type: 'linear',
          position: 'left',
          stacked: true,
          ticks: {
            min: 0,
            beginAtZero: true
          },
          scaleLabel: {display: true, labelString: data.yaxisLabel}
        }, {
          id: 'right',
          type: 'linear',
          position: 'right',
          ticks: {
            min: 0,
            max: 100,
            beginAtZero: true,
            callback(value, index, values) {
              return value + '%';
            }
          }
        }]
      };
      // if there are multiple datasets for a pareto, we need to get totals to sort it
      const ds = [];
      const pLabels = [];
      const lds = {
        type: 'line',
        fill: false,
        labelsMap: [],
        yAxisID: 'right',
        /* xAxisID: 'bottom', */
        data: [],
        backgroundColor: '#153B4F',
        borderColor: '#1AACE5'
      };
      let theTotal = 0;
      let pTotal = 0;
      let totals = [];
      _.each(data.datasets, (ref, idx) => {
        _.each(ref.data, (val, column) => {
          if (totals[column]) {
            totals[column].value += val;
            theTotal += val;
          } else {
            totals[column] = {item: column, value: val};
            theTotal += val;
          }
        });
      });
      // now we have the rows...sort by value highest to lowest
      totals = _.sortBy(totals, ['value']).reverse();
      data.totals = totals;
      // okay now that we know which column is tallest, reorder each dataset to match that
      const nds = [];
      _.each(totals, (ref) => {
        const col = ref.item;
        _.each(data.datasets, (ds, idx) => {
          // grab this column and put into the collection
          if (!nds[idx]) {
            nds[idx] = _.cloneDeep(data.datasets[idx]);
            nds[idx].data = [];
          }
          nds[idx].data.push(ds.data[col]);
        });
        pLabels.push(data.labels[col]);
        if (theTotal) {
          const pct = ref.value / theTotal * 100;
          pTotal += pct;
          if (pTotal > 100) {
            pTotal = 100;
          }
          lds.data.push(pTotal);
          lds.labelsMap.push({primary: data.labels[col], count: pTotal});
        } else {
          lds.data.push(0);
          lds.labelsMap.push({primary: data.labels[col], count: 0});
        }
      });

      if (nds.length) {
        nds[0].yAxisID = 'left';
      }

      data.labels = pLabels;
      data.datasets = [
        lds
      ];
      _.each(nds, (item) => {
        data.datasets.push(item);
      });
    }
    // okay - the data is set - draw the chart
    if (this.currentReportChart) {
      this.currentReportChart.destroy();
      this.currentReportChart = null;
    }

    // if (chartOpts.legend) {
    //   chartOpts.legend.onClick = (event: Event, legendItem: any) => {
    //     const navigationOptions: any = _.clone(opts);
    //     navigationOptions.reportId = reportId;
    //
    //     if (_.isUndefined(legendItem.index)) {
    //       navigationOptions.primaryLabel = data.datasets[legendItem.datasetIndex].labelsMap[0].primary;
    //     } else {
    //       navigationOptions.primaryLabel = data.datasets[0].labelsMap[legendItem.index].primary;
    //       navigationOptions.secondaryLabel = data.datasets[0].labelsMap[legendItem.index].secondary;
    //     }
    //     this.goToDetailPage(navigationOptions);
    //   };
    // }

    if (reportId) {
      chartOpts.onClick = (event: Event | any) => {
        let currentElement: any = _.get(this.currentReportChart.getElementAtEvent(event), `[0]`);
        if (currentElement && !_.isUndefined(currentElement._datasetIndex)) {
          this.onChartClick(opts, data, currentElement, reportId);
        } else {
          currentElement = _.first(this.currentReportChart.getElementsAtEventForMode(event, 'nearest', {intersect: false}));
          const maxOffsetY: number = _.get(currentElement, '_yScale.bottom');

          if (maxOffsetY && maxOffsetY > event.offsetY) {
            this.onChartClick(opts, data, currentElement, reportId);
          }
        }
      };
    }

    $('.custom-legend').remove();
    if (opts.customLegend) {
      chartOpts.legendCallback = (chart) => {
        const dataset: any = _.last(chart.data.datasets);
        return _.map(dataset.data, (dataItem: any, index: number) => `
          <div class="custom-legend-item" data-index="${index}">
            <div class="background-block" style="background-color: ${dataset.backgroundColor[index]}"></div>
            ${chart.data.labels[index] || ''}
          </div>
        `).join('');
      };

      chartOpts.legend = Object.assign({}, opts.legend, {display: false});
    }

    this.currentReportChart = new Chart($(theTarget), {
      type: chartType,
      plugins,
      options: chartOpts,
      data
    });

    if (opts.customLegend) {
      const customLegendElement = $('<div class="custom-legend"></div>');

      customLegendElement.html(this.currentReportChart.generateLegend());
      const customLegendValues: any = {};

      customLegendElement.off('click').on('click', (event: any) => {
        const index: string = _.get($(event.target).closest('.custom-legend-item')[0], 'dataset.index', null);

        if (index) {
          _.each(this.currentReportChart.data.datasets, (dataset: any, datasetIndex: number) => {
            const datasetMeta: any = this.currentReportChart.getDatasetMeta(datasetIndex);
            const currentValue = datasetMeta.controller._data[index];

            if (currentValue === null) {
              datasetMeta.controller._data[index] = customLegendValues[index][datasetIndex];
              $(event.target).closest('.custom-legend-item').removeClass('crossed');
            } else {
              if (!customLegendValues[index]) {
                customLegendValues[index] = {};
              }
              customLegendValues[index][datasetIndex] = currentValue;
              datasetMeta.controller._data[index] = null;
              $(event.target).closest('.custom-legend-item').addClass('crossed');
            }
            this.currentReportChart.update();
          });
        }
      });
      $(theTarget).closest('div').after(customLegendElement);
    }

    data.labels.length ? $('.no-data-msg').hide() : $('.no-data-msg').show();

    return this.currentReportChart;
  }

  public renderVirtualScrollTable(tableId, tableDef, data) {
    const tableElement: any = $(`${tableId}`);

    if ($(`${tableId} thead`).length) {
      tableElement.DataTable().destroy();
      tableElement.html('');
    }

    const dataTableOptions = {
      searching: false,
      info: false,
      retrieve: true,
      stateSave: true,
      ordering: false,
      data,
      paging: true,
      scrollX: true,
      scroller: {
        rowHeight: 60,
        displayBuffer: 18,
        loadingIndicator: true,
        boundaryScale: 0.15
      },
      deferRender: true,
      scrollY: '500px',
      columns: _.map(tableDef.columns, (column) => {
        const columnConfig: any = {
          title: column.title,
          data: column.fromID || column.id
        };

        if (column.func) {
          columnConfig.render = (data, type, row) => column.func(data, row, type);
        }

        return columnConfig;
      }),
      buttons: {
        buttons: [{extend: 'excel'}]
      }
    };

    const table: any = tableElement.DataTable(dataTableOptions);
    tableElement.width('100%');
    table.draw();

    $(`${tableId} tbody`).off('click').on('click', 'tr', (event: Event) => {
      const data = table.row(event.currentTarget).data();
      const responseID = _.get(data, 'items[0].responseID');

      if (data && tableDef.onClickRow && responseID) {
        tableDef.onClickRow(responseID);
      }
    });
  }

  public getTeamOptions(teams?: any): any[] {
    const teamsOptions: any[] = _.sortBy(_.cloneDeep(teams || this.teams), 'name');
    teamsOptions.unshift({
      name: `Viewer's Primary Team`,
      groupID: -1
    });

    return teamsOptions;
  }

  public getUsersOptions(users?: any): any[] {
    const usersOptions: any[] = _.sortBy(_.cloneDeep(users || this.accounts), 'name');
    usersOptions.unshift({
      name: this.translate.instant('MGMT_DETAILS.User_Supervisor'),
      userID: -2
    });
    usersOptions.unshift({
      name: this.translate.instant('MGMT_DETAILS.Target_User'),
      userID: -1
    });

    return usersOptions;
  }

  public getWorkerOptions(users?: any): any[] {
    const usersOptions: any[] = _.sortBy(_.cloneDeep(users || this.accounts), item => this.accountsService.fullUserName(item.userID));
    if (this.subscriberService.usesAnonymous()) {
      usersOptions.unshift(this.accountsService.createAccountObject({
        firstname: this.translate.instant('SHARED.Anonymous'),
        userID: -1
      }));
    }
    return usersOptions;
  }

  public updateGraphFields(specificFieldName?: string) {
    const primaryFieldName = `${this.reportType}PrimaryField`;
    const secondaryFieldName = `${this.reportType}SecondaryField`;
    const primaryFieldValue: any = $(`${this.formPrefixId}${primaryFieldName}`).val();
    const secondaryFieldValue: any = $(`${this.formPrefixId}${secondaryFieldName}`).val();
    const periodFieldValue: any = $(`${this.formPrefixId}period`).val();
    const extraOptions: any[] = [];

    if (periodFieldValue && periodFieldValue !== 'none') {
      extraOptions.push({id: 'period', description: 'Period'});
    }

    if (specificFieldName === 'secondary' || !specificFieldName) {
      this.syncOrganizeField(primaryFieldValue, secondaryFieldName);
    }
    if (specificFieldName === 'primary' || !specificFieldName) {
      this.syncOrganizeField(secondaryFieldValue, primaryFieldName);
    }
    this.replaceGraphFieldsBy(`${this.reportType}GraphPrimary`, [primaryFieldValue, secondaryFieldValue], extraOptions);
  }

  public syncPeriodValues(id, data): void {
    const periodField = _.find(this.fields, {name: 'period'});

    if (periodField) {
      const period = $(id).val();

      if (data === 'custom') {
        data = this.reportService.buildPeriodListByCustomDate(this.customDateRange);
      }

      const fieldOptions: any = Object.assign(periodField, {
        options: this.reportService.buildPeriodList(data)
      });
      this.replaceSelectOptionsWith(id, fieldOptions, {period});
    }
  }

  public syncStates(observationTypes: any[]): void {
    const states: any[] = _.get(_.find(this.formConfig.fields, {name: 'states'}), 'options');
    const stateId = `#${this.formConfig.prefix}states`;
    observationTypes = observationTypes || [];

    const observationMap: { [key: string]: string | string[] } = {
      open: 'all',
      unassigned: 'all',
      fixed: ['condition', 'quality', 'pi'],
      closed: ['condition', 'quality', 'pi'],
    };

    _.each(states, (state) => {
      const currentStateElement: any = $(`${stateId}${state.id}`);

      if (observationMap[state.id] === 'all' || _.intersection(observationMap[state.id], observationTypes).length || observationTypes.length === 0) {
        currentStateElement.prop('disabled', false).closest('ion-col').show();
      } else {
        currentStateElement.prop('disabled', true).closest('ion-col').hide();
      }
    });
  }

  public async setOptionsByChecks(checkList: string[], checkTypes: string[] = []) {
    this.loadingService.enable().then(() => {

      if (checkList) {
        const checks: any = {};

        if (!checkList.length && checkTypes.length) {
          const c = this.checksService.getChecksByType(checkTypes);
          if (c) {
            checkList = _.map(c, 'checkID');
          }
        }

        _.each(checkList, checkID => {
          checks[checkID] = this.checksService.getCheck(checkID);
        });
        this.updateFieldByName('deploymentName', this.deploymentService.getGroupedDeploymentsByChecks(checks));
      } else {
        this.updateFieldByName('targetLocations', this.locations);
        this.updateFieldByName('targetAssetType', this.settingsService.getSettingSync('assetType', this.userdataService.locations));
        this.updateFieldByName('targetAssets', this.assetsService.asset.data);
        this.updateFieldByName('targetTeams', this.getTeamOptions());
        this.updateFieldByName('targetPermissions', this.permissionsService.permissions.data);
        this.updateFieldByName('targetUsers', this.accounts);
        this.updateFieldByName('users', this.accounts);
        this.updateFieldByName('groups', this.getTeamOptions());
        this.updateFieldByName('deploymentName', this.deploymentService.getGroupedDeploymentsByChecks(this.checksService.checks.data));
      }
      this.loadingService.disable();
    });
  }

  public syncGraphSecondaryField(fieldId: string, targetFieldName: string, values: any[] = null): void {
    if (!values) {
      values = <any[]>$(`${this.formPrefixId}${fieldId}`).val();
    }
    values = _.map(values, (value) => ({id: value}));
    const options: any[] = _.intersectionBy(this.reportColumnOptions, values, 'id');
    const targetFieldId = `${this.formPrefixId}${targetFieldName}`;
    const targetFieldValue: any = $(targetFieldId).val();
    const fieldOptions: any = Object.assign(_.find(this.formConfig.fields, {name: targetFieldName}) || {}, {options});

    this.replaceSelectOptionsWith(targetFieldId, fieldOptions, {[targetFieldName]: targetFieldValue});
  }

  public syncFieldsBySelectedLocations(locationIds): void {
    this.loadingService.enable().then(() => {
      const accounts: any[] = this.accountsService.getUserlist(locationIds);
      const teams: any[] = this.getTeamOptions(this.teamsService.getTeamList(locationIds));
      const settingsFieldsMap: { [key: string]: string } = {
        categories: 'category',
        behaviors: 'behavior',
        mitigations: 'mitigation',
        qualitycats: 'quality',
        compliments: 'compliment',
      };

      this.updateFieldByName('zones', this.zoneService.getGroupedZonesByLocations(locationIds));
      this.updateFieldByName('creators', this.getWorkerOptions());
      _.each(['owners', 'users', 'recipients'], (fieldName: string) => this.updateFieldByName(fieldName, accounts));
      _.each(['groups', 'targetGroups'], (fieldName: string) => this.updateFieldByName(fieldName, teams));
      _.each(settingsFieldsMap, (settingsFieldKey: string, fieldName: string) => {
        this.updateFieldByName(fieldName, this.settingsService.getSettingSync(settingsFieldKey, locationIds));
      });

      this.loadingService.disable();
    });
  }

  public defineCheckVisibilityByValue(types: string[]) {
    const checks = this.checksService.getChecksByType(types);
    this.loadingService.enable().then(() => {
      this.updateFieldByName('checkName', checks);
      this.updateFieldByName('checkDetailName', checks);
      this.loadingService.disable();
    });
  }

  public showFilters(data?) {
    setTimeout(() => {
      const selectedFields = _.filter(this.filtersFields, (field) => _.includes(this.filters, field.name));
      _.each(this.removedFilters, (item) => {
        $(`#${this.formConfig.prefix}${item}_container`).remove();
      });
      this.formBuilderService.addFieldsTo(this.formConfig, selectedFields);
    });
  }

  public prepareFields(data) {
    if (data.filtersFields && data.filtersFields.length) {
      this.filters = data.filtersFields;
      (<any>_.find(this.fields, {name: 'filtersFields'})).inputs.selectedItems = data.filtersFields;
      _.each(this.filtersFields, (item) => {
        if (_.includes(data.filtersFields, item.name)) {
          this.initFilters.push(item);
        }
      });
    } else {
      const fields = [];
      _.each(this.filtersFields, (item) => {
        const itemData = _.get(data, item.name);
        if (itemData && itemData.length) {
          fields.push(item.name);
          this.initFilters.push(item);
        }
      });
      this.filters = fields;
      (<any>_.find(this.fields, {name: 'filtersFields'})).inputs.selectedItems = fields;
    }
  }

  public getSelectedItems() {
    setTimeout(() => {
      const items = this.filters;
      return items;
    });
  }

  public onRemoveField(field) {
    this.filters = _.remove(this.filters, (item) => item !== field.name);
    const originalValue = (<any>_.find(this.fields, {name: 'filtersFields'})).componentRef.selectedItems;
    (<any>_.find(this.fields, {name: 'filtersFields'})).componentRef.selectedItems = this.filters;
    (<any>_.find(this.fields, {name: 'filtersFields'})).componentRef.formValue = this.filters;
    this.formBuilderService.markFieldAsChanged(`${this.formPrefixId}Form`, 'filtersFields', originalValue, this.filters);
  }

  protected replaceSelectOptionsWith(id, options, data) {
    setTimeout(() => {
      this.formBuilderService.replaceSelectOptionsWith(id, this.formConfig, options, data);
    }, 500);
  }

  protected showDeactivated(value, ref: any): string {
    if (ref.disabledAt || (_.has(ref, 'active') && !ref.active) ) {
      return value + ' (' + this.translate.instant('SHARED.Deactivated') + ')';
    } else {
      return value;
    }
  }

  protected isFieldDisabledValid(ref): boolean {
    if (_.has(ref, 'disabledAt') && ref.disabledAt) {
      return false;
    }
    if (_.has(ref, 'active') && !ref.active) {
      return false;
    }
    return true;
  }

  protected updateFieldByName(name: string, options: any): any {
    const fieldId = `#${this.formConfig.prefix}${name}`;
    const data: any = $(fieldId).val();
    const filterField = _.find(this.filtersFields, {name});
    if (filterField) {
      const fieldOptions: any = Object.assign(filterField, {options});
      this.replaceSelectOptionsWith(fieldId, fieldOptions, {[name]: data});
    }
  }

  protected filterPermission(permission): boolean {
    permission.description = this.translate.instant(permission.description);

    if (permission.id === 'corvex') {
      return _.get(this.userdataService.Permissions, 'corvex');
    } else if (permission.id === 'sadmin') {
      return _.get(this.userdataService.Permissions, 'sadmin') || _.get(this.userdataService.Permissions, 'corvex');
    } else {
      return true;
    }
  }

  protected replaceGraphFieldsBy(name: string, values: string[], extraOptions: any[] = []) {
    let newOptions: any[] = _.filter(_.map(values, (value: string) => _.find(this.reportFieldOptions, <any>{id: value})), Boolean) || [];
    newOptions = _.reject([...newOptions, ...extraOptions], <any>{id: 'none'});

    if (newOptions.length || extraOptions.length) {
      const fieldId = `${this.formPrefixId}${name}`;
      const values: string[] | number[] = <string[] | number[]>$(fieldId).val();
      const fieldOptions: any = Object.assign(_.find(this.formConfig.fields, {name}), {
        options: newOptions
      });
      this.replaceSelectOptionsWith(fieldId, fieldOptions, {[name]: values});
    }
  }

  protected defineResponseTypeVisibilityByValue(types: string[]): void {
    setTimeout(() => {
      if (types && types.length) {
        $('.report-field.deploymentType').hide();
        _.each(types, (type) => {
          $('.report-field.deploymentType.' + type + 'Deployment').show();
        });
      } else {
        $('.report-field.deploymentType').show();
      }
      this.syncStates(types);
    });
  }

  protected syncFieldsBySelectedTargetLocations(locationIds): void {
    this.loadingService.enable().then(() => {
      this.updateFieldByName('targetZones', this.zoneService.getGroupedZonesByLocations(locationIds));
      const targetField = find(this.filtersFields, {name: 'targetAssets'}) as FormField;

      if (targetField?.componentRef) {
        targetField.componentRef.formValue = [];
        (targetField.componentRef as FolderPickerComponent).ngOnInit();
      }
      this.loadingService.disable();
    });
  }

  private onChartClick(opts, data, currentElement, reportId): void {
    if (this.permissionsService.canView('admin')) {
      const navigationOptions: any = _.clone(opts);
      const currentDataSet = _.cloneDeep(data.datasets[currentElement._datasetIndex]);
      if (navigationOptions.graphPrimary === 'period') {
        navigationOptions.index = currentElement._index;
      }

      if (navigationOptions.graphType === 'pareto' && currentDataSet.type !== 'line') {
        const labelsMap = _.cloneDeep(currentDataSet.labelsMap);
        _.each(data.totals, (total: any, index) => {
          currentDataSet.labelsMap[index] = labelsMap[total.item];
        });
      }
      navigationOptions.primaryLabel = currentDataSet.labelsMap[currentElement._index].primary;
      navigationOptions.secondaryLabel = currentDataSet.labelsMap[currentElement._index].secondary;
      navigationOptions.primaryID = currentDataSet.labelsMap[currentElement._index].primaryID;
      navigationOptions.reportId = reportId;
      this.goToDetailPage(navigationOptions);
    }
  }

  private goToDetailPage(opts): void {
    const unnecessaryOptions: string[] = ['graphType', 'name'];
    const navigationOptions: any = _.omit(_.pickBy(opts, (opt) => _.isNumber(opt) || _.size(opt)), unnecessaryOptions);
    const url: string = _.first(_.split(this.router.url, ';'));
    navigationOptions.prevState = _.last(_.split(url, '/'));

    this.router.navigate(['/pages/reporting/detail'], {queryParams: navigationOptions});
  }

  private syncOrganizeField(rejectValue: any, targetFieldName: string): void {
    const fieldId = `#${this.formConfig.prefix}${targetFieldName}`;
    let options: any[] = _.reject(this.reportFieldOptions, {id: rejectValue});
    const values: string[] | number[] = <string[] | number[]>$(fieldId).val();

    if (this.reportFieldOptions_temporarily) {
      options = _.reject(this.reportFieldOptions_temporarily, {id: rejectValue});
    }

    const fieldOptions: any = Object.assign(_.find(this.fields, {name: targetFieldName}) || {}, {
      options
    });
    this.replaceSelectOptionsWith(fieldId, fieldOptions, {[targetFieldName]: values});
  }

  public preparationColumns(columns) {
    return _.map(columns, (column) => {
      const columnConfig: any = {
        title: `${column.title} <span class="sort-icon"></span>`,
        data: column.fromID || column.id,
        exportTitle: `${column.label || column.title}`,
        className: column.className,
        render: (data, type, row) => {
          if (type === 'sort') {
            return _.get(row, 'items[0][column.id]') || data;
          } else {
            return data;
          }
        }
      };

      if (column.func) {
        columnConfig.render = (data, type, row) => column.func(data, row, type);
      }

      if (column.render) {
        columnConfig.render = column.render;
      }

      return columnConfig;
    });
  }

  public addColumn(columnConfig: TableColumn | any, index: number, fromID: string, label: string, isTotal = false) {
    label = isTotal ? this.translate.instant('SHARED.Total') : label;

    return {
      id: columnConfig.id,
      title: index ? columnConfig.label : `${label} <br> ${columnConfig.label}`,
      label: index ? columnConfig.label : `${label} ${columnConfig.label}`,
      fromID,
      className: index ? 'grouped-column-title' : '',
      headerClass: 'text-left',
      class: ` tableexport-${columnConfig.cellType || 'string'}`,
      minwidth: '75px'
    };
  }

  private getAssetsByCurrentLocations(ids?: number[]) {
    const locations: number[] = ids?.length ? ids : this.userdataService.locations;
    return this.assetsService.getAssetsByLocations(locations);
  }
}
