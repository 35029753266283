import { Injectable, Injector } from '@angular/core';

import { BaseField, ReportBaseClass } from '@modules/reporting/models/types/base';
import { ObsDetailModelService } from '@modules/reporting/models/types/obsDetail/service';
import * as _ from 'lodash';
import { DataToIncludeFormComponent, SelectFiltersFormComponent } from '@modules/reporting/components';
import { Feature, FormField, Module } from '@services';
import * as moment from 'moment';
import { FolderDataType } from '@modules/management/modules/folders/services/folders.service';

@Injectable()
export class ObsDetailReport extends ReportBaseClass {

  public dataToIncludeFields: any[] = [];
  public reportType = 'obsDetail';

  private obsDetailModelService: ObsDetailModelService = this.injector.get(ObsDetailModelService);
  public reportColumnOptions = this.obsDetailModelService.getColumns();
  public reportFieldOptions = this.obsDetailModelService.getFieldOptions();

  filtersFields = [
    {
      containerClass: 'report-field obsdets observation',
      title: this.translate.instant('SHARED.Observation_Type'),
      name: 'obstype',
      type: 'selectmenu',
      role: 'none',
      multiple: true,
      canDelete: true,
      placeholder: this.translate.instant('SHARED.Any_Type'),
      options: this.getObservationTypes(),
      onRemoveField: (field: any) => this.onRemoveField(field),
      onChange: (selections) => this.defineObstypeVisibilityByValue(selections)
    },
    {
      containerClass: 'report-field obsdets observation observationType conditionObservation qualityObservation piObservation ',
      title: this.translate.instant('REPORTING.EDIT_Observation_Status'),
      name: 'states',
      type: 'checkbox',
      role: 'none',
      class: 'inline-checkboxes',
      multiple: true,
      canDelete: true,
      placeholder: 'Any Status',
      options: [
        {
          id: 'open',
          description: this.translate.instant('SHARED.Open')
        },
        {
          id: 'unassigned',
          description: this.translate.instant('SHARED.Unassigned')
        },
        {
          id: 'fixed',
          description: this.translate.instant('SHARED.Marked_as_Fixed')
        },
        {
          id: 'closed',
          description: this.translate.instant('SHARED.Closed')
        }
      ],
      onRemoveField: (field: any) => this.onRemoveField(field),
    },
    {
      containerClass: 'report-field obsdets observation observationType conditionObservation',
      name: 'ownershipStatus',
      title: this.translate.instant('REPORTING.EDIT_Ownership_Status'),
      type: 'checkbox',
      role: 'none',
      class: 'inline-checkboxes',
      multiple: true,
      canDelete: true,
      placeholder: 'Any Status',
      options: [
        {
          id: 'assigned',
          description: this.translate.instant('SHARED.Assigned')
        },
        {
          id: 'unassigned',
          description: this.translate.instant('SHARED.Unassigned')
        }
      ],
      onRemoveField: (field: any) => this.onRemoveField(field),
    },
    {
      containerClass: 'report-field obsdets observation observationType conditionObservation',
      type: 'flipswitch',
      name: 'hasWorkorder',
      title: this.translate.instant('REPORTING.EDIT_Has_Workorder'),
      onText: this.translate.instant('SHARED.Yes'),
      offText: this.translate.instant('SHARED.No'),
      value: 1,
      canDelete: true,
      onRemoveField: (field: any) => this.onRemoveField(field),
    },
    {
      containerClass: 'report-field obsdets observation observationType conditionObservation',
      title: this.translate.instant('SHARED.Severity'),
      name: 'severities',
      type: 'checkbox',
      class: 'inline-checkboxes',
      role: 'none',
      multiple: true,
      canDelete: true,
      options: this.reportService.severities,
      onRemoveField: (field: any) => this.onRemoveField(field),
      func: (item) => {
        const dot = this.reportService.colorDot(item.id);
        return '<img width=\'24\' src=\'' + dot + '\' />';
      }
    },
    {
      containerClass: 'report-field obsdets observation observationType conditionObservation',
      title: this.translate.instant('SHARED.Likelihood'),
      name: 'likelihoods',
      type: 'checkbox',
      multiple: true,
      canDelete: true,
      class: 'inline-checkboxes',
      role: 'none',
      options: this.reportService.likelihoods,
      onRemoveField: (field: any) => this.onRemoveField(field),
      func: (item) => {
        const dot = this.reportService.colorDot(item.id);
        return '<img width=\'24\' src=\'' + dot + '\' />';
      }
    },

    {
      containerClass: 'report-field obsdets observation observationType behaviorObservation',
      title: this.translate.instant('MGMT_LIST.Coaching_Opportunity_Categories'),
      name: 'behaviors',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.Any_Category'),
      multiple: true,
      canDelete: true,
      valueProperty: 'messageID',
      options: this.settingsService.getSettingSync('behavior', this.userdataService.locations),
      onRemoveField: (field: any) => this.onRemoveField(field),
      func: (ref) => this.showDeactivated(ref.messageTitle, ref)
    },
    {
      containerClass: 'report-field obsdets observation observationType behaviorObservation',
      title: this.translate.instant('REPORTING.EDIT_Mitigation_Categories'),
      name: 'mitigations',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.Any_Category'),
      multiple: true,
      canDelete: true,
      valueProperty: 'messageID',
      options: this.settingsService.getSettingSync('mitigation', this.userdataService.locations),
      onRemoveField: (field: any) => this.onRemoveField(field),
      func: (ref) => this.showDeactivated(ref.messageTitle, ref)
    },
    {
      containerClass: 'report-field obsdets observation observationType qualityObservation',
      title: this.translate.instant('REPORTING.EDIT_Quality_Categories'),
      name: 'qualitycats',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.Any_Category'),
      multiple: true,
      canDelete: true,
      valueProperty: 'messageID',
      options: this.settingsService.getSettingSync('quality', this.userdataService.locations),
      onRemoveField: (field: any) => this.onRemoveField(field),
      func: (ref) => this.showDeactivated(ref.messageTitle, ref)
    },
    {
      containerClass: 'report-field obsdets observation observationType conditionObservation',
      title: this.translate.instant('REPORTING.EDIT_Condition_Observation_Categories'),
      name: 'categories',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.Any_Category'),
      multiple: true,
      canDelete: true,
      valueProperty: 'messageID',
      options: this.settingsService.getSettingSync('category', this.userdataService.locations),
      onRemoveField: (field: any) => this.onRemoveField(field),
      func: (ref) => this.showDeactivated(ref.messageTitle, ref)
    },
    {
      containerClass: 'report-field obsdets observation observationType piObservation',
      title: this.translate.instant('REPORTING.EDIT_PI_Categories'),
      name: 'picats',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.Any_Category'),
      multiple: true,
      canDelete: true,
      valueProperty: 'messageID',
      options: this.settingsService.getSettingSync('pi', this.userdataService.locations),
      onRemoveField: (field: any) => this.onRemoveField(field),
      func: (ref) => this.showDeactivated(ref.messageTitle, ref)
    },
    {
      containerClass: 'report-field obsdets observation observationType complimentObservation',
      title: this.translate.instant('REPORTING.EDIT_Thumbs_Up_Categories'),
      name: 'compliments',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.Any_Category'),
      multiple: true,
      canDelete: true,
      valueProperty: 'messageID',
      options: this.settingsService.getSettingSync('compliment', this.userdataService.locations),
      onRemoveField: (field: any) => this.onRemoveField(field),
      func: (ref) => this.showDeactivated(ref.messageTitle, ref)
    },

    {
      containerClass: 'report-field obsdets obsDetail observation PPESummary team worker zone',
      title: this.translate.instant('SHARED.Date_Created'),
      placeholder: this.translate.instant('REPORTING.EDIT_Any_Date'),
      name: 'dateCreated',
      type: 'dateRangePicker',
      required: false,
      multiple: false,
      canDelete: true,
      options: this.reportService.timespans,
      onChange: (value: any) => {
        if (value.type === 'custom') {
          _.assign(this.reportService.customDateCreated, value.range);
        }
      },
      onRemoveField: (field: any) => this.onRemoveField(field),
      originalOrder: true,
      default: null
    },
    {
      containerClass: 'report-field obsdets obsDetail observation PPESummary team worker zone',
      title: this.translate.instant('SHARED.Date_Fixed'),
      placeholder: this.translate.instant('REPORTING.EDIT_Any_Date'),
      name: 'dateFixed',
      type: 'dateRangePicker',
      required: false,
      multiple: false,
      canDelete: true,
      options: this.reportService.timespans,
      onChange: (value: any) => {
        if (value.type === 'custom') {
          _.assign(this.reportService.customDateFixed, value.range);
        }
      },
      onRemoveField: (field: any) => this.onRemoveField(field),
      originalOrder: true,
      default: null
    },
    {
      containerClass: 'report-field obsdets obsDetail observation PPESummary team worker zone',
      title: this.translate.instant('SHARED.Date_Closed'),
      placeholder: this.translate.instant('REPORTING.EDIT_Any_Date'),
      name: 'dateClosed',
      type: 'dateRangePicker',
      required: false,
      multiple: false,
      canDelete: true,
      options: this.reportService.timespans,
      onChange: (value: any) => {
        if (value.type === 'custom') {
          _.assign(this.reportService.customDateClosed, value.range);
        }
      },
      onRemoveField: (field: any) => this.onRemoveField(field),
      originalOrder: true,
      default: null
    },
    {
      containerClass: 'report-field obsdets observation',
      title: this.translate.instant('REPORTING.EDIT_Creators'),
      name: 'creators',
      type: 'selectmenu',
      multiple: true,
      canDelete: true,
      valueProperty: 'userID',
      placeholder: this.translate.instant('REPORTING.EDIT_Any_Creator'),
      options: this.getWorkerOptions(),
      onRemoveField: (field: any) => this.onRemoveField(field),
      func: (ref) => this.showDeactivated(this.userService.getFullname(ref.userID), ref)
    },
    {
      containerClass: 'report-field obsdets observation',
      title: this.translate.instant('SHARED.Creator_Role'),
      name: 'creatorRole',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.Any_Role'),
      multiple: true,
      canDelete: true,
      valueProperty: 'roleID',
      options: this.rolesService.roles.data,
      func: (role) => this.showDeactivated(role.name, role),
      onRemoveField: (field: any) => this.onRemoveField(field),
      originalOrder: true
    },
    {
      containerClass: 'report-field obsdets observation',
      title: this.translate.instant('REPORTING.EDIT_Owner_Roles'),
      name: 'ownerRole',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.Any_Role'),
      multiple: true,
      canDelete: true,
      valueProperty: 'roleID',
      options: this.rolesService.roles.data,
      func: (role) => this.showDeactivated(role.name, role),
      onRemoveField: (field: any) => this.onRemoveField(field),
      originalOrder: true
    },
    {
      containerClass: 'report-field obsdets observation',
      title: this.translate.instant('REPORTING.EDIT_Creator_Teams'),
      name: 'creatorGroups',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.Any_Team'),
      multiple: true,
      canDelete: true,
      valueProperty: 'groupID',
      options: this.getTeamOptions(),
      onRemoveField: (field: any) => this.onRemoveField(field),
      func: (group) => this.showDeactivated(group.name, group),
      originalOrder: true
    },
    {
      containerClass: 'report-field obsdets observation',
      title: this.translate.instant('REPORTING.EDIT_Owners'),
      name: 'owners',
      type: 'selectmenu',
      multiple: true,
      canDelete: true,
      placeholder: this.translate.instant('REPORTING.EDIT_Any_Owner'),
      valueProperty: 'userID',
      options: this.accounts,
      onRemoveField: (field: any) => this.onRemoveField(field),
      func: (ref) => this.showDeactivated(this.userService.getFullname(ref.userID), ref)
    },
    this.baseFields[BaseField.Groups],
    {
      containerClass: 'report-field obsdets observation team worker zone',
      title: this.translate.instant('REPORTING.EDIT_Tag'),
      name: 'tags',
      type: 'selectmenu',
      placeholder: this.translate.instant('REPORTING.EDIT_Any_Tag'),
      multiple: true,
      canDelete: true,
      valueProperty: 'tagID',
      options: _.sortBy(this.settingsService.customTags.data, 'tag'),
      func: (tag) => this.showDeactivated(tag.tag, tag),
      onRemoveField: (field: any) => this.onRemoveField(field),
      originalOrder: true
    },
    this.baseFields[BaseField.Locations],
    {
      containerClass: 'report-field obsdets team worker zone observation',
      title: this.translate.instant('SHARED.Zones'),
      name: 'zones',
      type: 'selectmenu',
      multiple: true,
      canDelete: true,
      placeholder: this.translate.instant('SHARED.Any_Zone'),
      options: this.zones,
      onRemoveField: (field: any) => this.onRemoveField(field),
      func: (ref) => this.showDeactivated(ref.id, ref)
    },
    {
      containerClass: 'report-field obsdets observation compliment observationType complimentObservation',
      title: this.translate.instant('REPORTING.EDIT_Receiving_Workers'),
      name: 'recipients',
      type: 'selectmenu',
      multiple: true,
      canDelete: true,
      placeholder: this.translate.instant('SHARED.EDIT_Any_Worker'),
      valueProperty: 'userID',
      options: this.accounts,
      onRemoveField: (field: any) => this.onRemoveField(field),
      func: (ref) => this.showDeactivated(this.userService.getFullname(ref.userID), ref)
    },
    {
      containerClass: 'report-field obsdets observation compliment observationType complimentObservation',
      title: this.translate.instant('SHARED.Notification__Receiving_Teams'),
      name: 'targetGroups',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.Any_Team'),
      multiple: true,
      canDelete: true,
      valueProperty: 'groupID',
      options: this.getTeamOptions(),
      func: (group) => this.showDeactivated(group.name, group),
      onRemoveField: (field: any) => this.onRemoveField(field),
      originalOrder: true
    },
    this.baseFields[BaseField.TargetAssets]
  ];
  public fields = [
    this.baseFields[BaseField.Timespan],
    this.baseFields[BaseField.LocationTime],
    // this.baseFields[BaseField.Period],
    {
      containerClass: 'custom-data-field report-field obsDetail',
      title: this.translate.instant('REPORTING.EDIT_Data_to_Include'),
      name: 'obsDetailReportColumns',
      type: 'customElement',
      component: DataToIncludeFormComponent,
      inputs: {
        options: _.cloneDeep(this.reportColumnOptions)
      },
      outputs: {
        onChanged: (selectedItems) => {
          const field = <FormField>_.find(this.formConfig.fields, {name: 'obsDetailReportColumns'});
          (field.componentRef as DataToIncludeFormComponent).selectedItems = selectedItems;
          this.dataToIncludeFields = _.filter(selectedItems);
        }
      },
      options: _.cloneDeep(this.reportColumnOptions)
    },
    {
      containerClass: 'report-field obsdets observation obsDetail ccsFormSubdivider',
      title: this.translate.instant('REPORTING.EDIT_Report_Data_Selection'),
      type: 'divider',
    },
    {
      containerClass: 'custom-data-field report-field obsDetail',
      title: this.translate.instant('SHARED.Select_Filters'),
      name: 'filtersFields',
      type: 'customElement',
      component: SelectFiltersFormComponent,
      inputs: {
        options: _.cloneDeep(this.filtersFields),
        selectedItems: []
      },
      outputs: {
        onChanged: (selectedItems) => {
          const field = <FormField>_.find(this.formConfig.fields, {name: 'filtersFields'});
          (field.componentRef as SelectFiltersFormComponent).selectedItems = selectedItems;
          this.removedFilters = _.difference(this.filters, selectedItems);
          this.filters = selectedItems;
          this.showFilters();
        }
      },
      options: _.cloneDeep(this.filtersFields)
    },
  ];

  constructor(protected injector: Injector) {
    super(injector);
  }

  public init(reportData) {
    const obsDetailReportColumns: any = _.find(this.formConfig.fields, {name: 'obsDetailReportColumns'});
    obsDetailReportColumns.inputs.reportID = this.messageID;
    this.defineObstypeVisibilityByValue(reportData.selectors.obstype);
    this.dataToIncludeFields = _.filter(_.get(reportData, 'selectors.extraColumns')) || [];
    super.init(reportData);
  }

  public async report(theTable, theChart, report, reportId?: number, isExport?: boolean) {
    this.reportData = report;
    const opts = _.cloneDeep(report.selectors);
    const primaryGroup: number = _.get(this.accountsService.getAccount(this.userdataService.userID), 'primaryGroup');

    if (primaryGroup) {
      opts.groups = _.map(opts.groups, (group: number) => group === -1 ? primaryGroup : group);
    }

    const timeByTimespan = this.getTimeByTimespan(opts.timespan, opts.startTime, opts.endTime);
    opts.startTime = timeByTimespan.startTime;
    opts.endTime = timeByTimespan.endTime;

    if (!opts.hasOwnProperty('locations') || opts.locations.length === 0) {
      opts.locations = this.userdataService.locations;
    }

    // prune out meaninless options - empty arrays are the same
    // as "all"

    _.each(_.keys(opts), item => {
      const rItem = opts[item];
      if (_.isArray(rItem)) {
        if (!rItem.length) {
          delete opts[item];
        }
      }
    });

    const r = await this.findInterval(opts);

    const tableDef = _.merge({}, this.tableDef);

    // create a table using the primary (and possibly secondary columns)
    const pRef: any = _.find(this.reportFieldOptions, {id: opts.primary});

    if (pRef) {
      tableDef.columns.push({
        id: pRef.id,
        title: pRef.label,
        fromID: pRef.fieldName,
        headerClass: 'text-left',
        class: 'tableexport-string'
      });
    }

    const totalGroup = opts.period === 'none' ? null : 'total';

    if (_.filter(opts.extraColumns).length) {
      _.each(opts.extraColumns, (colname) => {
        const c: any = _.find(this.reportColumnOptions, {id: colname});
        let t = '';
        if (c.hasOwnProperty('cellType')) {
          t += ' tableexport-' + c.cellType;
        } else {
          t += ' tableexport-string';
        }
        tableDef.columns.push({
          id: c.id,
          title: c.label,
          func: (...params) => {
            const [, reportItem, type] = params;
            return c.func(reportItem?.items || [], reportItem?.items?.[0], type);
          },
          fromID: 'total' + colname,
          headerClass: 'text-left',
          colgroup: totalGroup,
          class: t,
          minwidth: '75px',
        });
      });
    }

    let rowRefs = [];
    const rows = _.flatten(_.values(this.filterCategories(r.rows, opts)));
    const pOrder = this.utils.sortArray(rows, [
      {
        name: 'label',
        function: (item) => {
          if (item && typeof item === 'string') {
            return item.toLowerCase();
          } else {
            return item;
          }
        }
      }]
    );
    const significantPrimaryKeys = [];
    const significantSecondaryKeys = [];

    _.each(pOrder, (item: any, pds) => {
      if (item.items.length || opts.includingAll) {
        if (_.indexOf(significantPrimaryKeys, item.label) < 0) {
          significantPrimaryKeys.push(item.label);
        }
        // push this data into a row
        const d = item;
        d.totalObservations = d.items.length;
        _.each(item.extras, (value, col) => {
          d['total' + col] = value;

        });
        _.each(item.intervals, (iref, name) => {
          d[name] = iref.items.length;
          _.each(iref.extras, (value, col) => {
            d[name + col] = value;
          });
        });
        rowRefs.push(d);
      }
    });

    if (theTable && tableDef?.columns?.length) {

      const s1 = 'total' + tableDef.columns[0].id;
      const isTimeType = s1.lastIndexOf('date');
      let s1Sorter;
      if (isTimeType < 0) {
        s1Sorter = obj => _.toString(obj[s1]).toLowerCase();
        rowRefs = _.orderBy(rowRefs, [s1Sorter], ['asc', 'desc']);
      } else {
        rowRefs = _.orderBy(rowRefs, (a) => moment(a[s1]).unix(), 'desc');
      }

      const rowRefsCopy = _.cloneDeep(rowRefs);
      if (isExport) {
        _.forEach(rowRefsCopy, (ref, key) => {
          _.forEach(ref, (item, name) => {
            if (_.includes(name, '-')) {
              const itemArray = _.split(item, ' ');
              if (itemArray && itemArray.length === 2) {
                const stringItems = _.split(itemArray[0], '/');
                let itemVal: number;
                if (stringItems && stringItems.length === 2) {
                  itemVal = +stringItems[0] / +stringItems[1];
                } else {
                  itemVal = +stringItems[0];
                }
                rowRefsCopy[key][name] = itemVal;
              }
            }
          });
        });
      }
      tableDef.data = rowRefsCopy;
      tableDef.columns = this.preparationColumns(tableDef.columns);
      this.tableService.showDataTable(theTable, tableDef);
    }
    $('.report-view-has-chart').hide();
  }

  public async findInterval(opts) {
    const intervals = this.observations.calendarInterval(opts.startTime, opts.endTime, opts.period, opts.timespan, opts.format);

    if (!this.observations.isLoaded()) {
      await this.observations.waitUntilLoaded();
    }

    const obs = this.observations.observations.data;
    const pRef: any = _.find(this.reportFieldOptions, {id: opts.detailRef});

    let primary: any = {};
    const primaryKeys = {};
    const secondaryKeys = {};
    const extraCols: any = {};
    const allExtraColls: string[] = [];

    primary = this.getPrimary(opts, null, intervals);

    if (opts?.targetAssets?.length) {
      opts.assetFolderTree = this.foldersDataService.getFolderTreeBy(FolderDataType.ASSET);
    }

    opts.intervals = {
    allTime : {
      start: opts.startTime,
      end: opts.endTime,
      timezones: {}
    }
    };

    // accumulate information about every observation OPENED in the range of the report
    _.forEach(obs, (ref: any) => {
      const ctime = ref.created * 1000;
      if (this.observations.outOfRange(opts, ref, ctime, opts.intervals.allTime)) {
        //if (ctime < opts.startTime || ctime > opts.endTime)
        // this one is out of our range
        return;
      }
      if (!this.observations.checkObservation(ref, opts)) {
        // this one doesn't match the filters
        return;
      }

      const createItem = (key: string, label: string) => {
        const newItem: any = {
          extras: {},
          items: [],
          label,
          intervals: {},
          [pRef.fieldName]: label,
          fieldName: pRef.fieldName
        };

        primaryKeys[label] = key;


        if (opts.interval !== 'none') {
          _.each(intervals, (iref, counter) => {
            const iname = 'p' + counter;
            newItem.intervals[iname] = {extras: {}, items: []};
          });
        }

        return newItem;
      };

      const findItem = (key: string, label: string) => {
        if (!_.has(primary, key)) {
          primary[key] = createItem(key, label);
        } else {
          const item = _.find(_.flatten([primary[key]]), <any>{label});

          if (!item) {
            primary[key] = [primary[key], createItem(key, label)];
          }
        }

        return _.find(_.flatten([primary[key]]), <any>{label});
      };

      // function to accumulate an individual item
      const addItem = (p, s) => {
        if (p[0] !== undefined) {
          let target = null;
          // we have a key
          const item: any = findItem(p[0], p[1]);

          item.items.push(ref);
          target = item;


          if (opts.interval !== 'none') {
            _.forEach(intervals, (iref, counter) => {
              // in each interval, add the data into the primary and secondary accumulators
              if (this.observations.outOfRange(opts, ref, ctime, iref)) {
                const iname = 'p' + counter;
                target.intervals[iname].items.push(ref);
                // we found a bucket for this observation
                return false;
              } else {
                return true;
              }
            });
          }
        }
      };

      // hold the primary key and label
      // get the primary field value from the record

      let pvList = _.get(ref, pRef.fieldName);
      if (pvList === undefined) {
        if (pRef.fieldRequired) {
          pvList = null;
        }
      }
      if (!_.isArray(pvList)) {
        pvList = [pvList];
      }
      _.each(pvList, (pv) => {
        let p = [];
        if (pv == null) {
          return;
        }
        if (pRef.hasOwnProperty('fieldFunc')) {
          // use the field function to derive the key and label
          // vrom the primary value
          p = pRef.fieldFunc(pv, ref);
        } else {
          // there is no translation function.  let's just use the id as
          // the key and the value
          p = [pv, pv];
        }
        if (p) {
          addItem(p, [null, null]);
        }
      });
    });

    // okay, we have every row populated now

    const extras = {};
    let extraColumns: any[] = _.get(opts, 'extraColumns', []);
    if (!_.isArray(extraColumns)) {
      extraColumns = [extraColumns];
    }

    _.each(extraColumns, (column) => {
      const c: any = _.find(this.reportColumnOptions, {id: column});
      _.each(primary, (primaryItem: any, key: string) => {
        _.each(_.flatten([primaryItem]), (pItem) => {
          if (c && c.func) {
            pItem.extras[column] = c.func(pItem.items);
          }
          _.each(_.flatten([primaryItem]), (pItem) => {
            _.each(_.uniq(allExtraColls), (colID) => {
              if (!pItem.extras[colID]) {
                pItem.extras[colID] = this.translate.instant('DASHPAGES.NA');
              }
            });
          });
        });
      });
    });

    // build an object of the accumulated data

    const r = {
      rows: opts.secondary ? primary : this.filterCategories(primary, opts),
      intervals,
      primaryKeys,
      secondaryKeys,
      extraCols
    };

    return r;
  }

  public getTableItems(queryParams: any): any {
    return {};
  }

  private defineObstypeVisibilityByValue(types: string[]): void {
    setTimeout(() => {
      if (types && types.length) {
        $('.report-field.observationType').hide();
        _.each(types, (type) => {
          $('.report-field.observationType.' + type + 'Observation').show();
        });
      } else {
        $('.report-field.observationType').show();
      }
      this.syncStates(types);
    });
  }

  private getObservationTypes() {
    const types = [
      {
        id: 'condition',
        description: this.translate.instant('SHARED.Unsafe_Condition'),
        permission: () => this.subscriberService.usesModule(Module.CONDITION)
      },
      {
        id: 'behavior',
        description: this.translate.instant('SHARED.Coaching_Opportunity'),
        permission: () => this.subscriberService.usesModule(Module.BEHAVIOR)
      },
      {
        id: 'quality',
        description: this.translate.instant('SHARED.CHANGING_TYPE_TS_quality'),
        permission: () => this.subscriberService.usesModule(Module.QUALITY, true)
      },
      {
        id: 'pi',
        description: this.translate.instant('REPORTING.EDIT_pi'),
        permission: () => {
          return this.subscriberService.usesModule(Module.PROCESS_IMPROVEMENT) &&
            (this.subscriberService.usesFeature(Feature.PI_GENERAL) || this.subscriberService.usesFeature(Feature.PI_WAITING))
        }
      },
      {
        id: 'compliment',
        description: this.translate.instant('SHARED.Thumbs-Up'),
        permission: () => this.subscriberService.usesModule(Module.COMPLIMENT)
      },
      {
        id: 'ca',
        description: this.translate.instant('SHARED.Corrective_Actions'),
        permission: () => this.subscriberService.usesModule(Module.CHECKS)
      },
      {
        id: 'si',
        description: this.translate.instant('SHARED.Opportunity'),
        permission: () => this.subscriberService.usesModule(Module.OPPORTUNITIES)
      },
      {
        id: 'ai',
        description: this.translate.instant('SHARED.Asset_Issues'),
        permission: () => this.subscriberService.usesModule(Module.ASSET_ISSUES)
      }
    ];


    return _.filter(types, (type) => {
      return type.permission ? type.permission() : true;
    });
  }
}
