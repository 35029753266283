import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';

import { CommsService } from '@services/comms/comms.service';
import { UtilsService } from '@services/utils/utils.service';
import { UserService } from '@services/user/user.service';
import { UserdataService } from '@services/userdata/userdata.service';
import { SubscriberService } from '@services/subscriber/subscriber.service';
import { BaseService } from '@services/abstract-base-service/abstract-base-service.service';
import { Events } from '@services/events/events.service';

import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SettingsService extends BaseService {

  public filterObject: any = {};
  // 1. Holds behaviors that populates the coaching opportunities menu
  public behaviors = {
    lastRequest: 0,
    data: []
  };

  // data buckets for various settings//
  // 2. Holds compliments that populates the thumbs up menu
  public compliments = {
    lastRequest: 0,
    data: []
  };
  // 3. Holds the team information
  public groups = {
    lastRequest: 0,
    data: []
  };
  // 4. Holds the mitigation measures required while creating coaching opportunities
  public mitigations = {
    lastRequest: 0,
    data: []
  };
  // 5. Holds the categories of observations
  public categories = {
    lastRequest: 0,
    data: []
  };
  // 6. Holds the emergencies
  public emergencies = {
    lastRequest: 0,
    data: []
  };
  // 7. Holds the qualityCategories
  public qualityCats = {
    lastRequest: 0,
    data: []
  };
  // 8. Holds the customTags
  public customTags = {
    lastRequest: 0,
    data: []
  };
  // 9. Holds the process improvement
  public piCats = {
    lastRequest: 0,
    data: []
  };
  // 10. Holds the checks types
  public checkType = {
    lastRequest: 0,
    data: []
  };
  // 11. Holds the asset type
  public assetType = {
    lastRequest: 0,
    data: []
  };
  // 12. Holds the groups type
  public groupID = {
    lastRequest: 0,
    data: []
  };
  // 13. Holds the checkReasons type
  public checkReason = {
    lastRequest: 0,
    data: []
  };
  // 14. Holds the consent form data
  public consent = {
    lastRequest: 0,
    data: []
  };
  public contentCategory = {
    lastRequest: 0,
    data: []
  };
  public aiCats = {
    lastRequest: 0,
    data: []
  };
  public tableMapNames = {
    behavior: 'behaviors',
    compliment: 'compliments',
    emergency: 'emergencies',
    group: 'groups',
    mitigation: 'mitigations',
    category: 'categories',
    quality: 'qualityCats',
    pi: 'piCats',
    assetType: 'assetType',
    checkType: 'checkType',
    groupID: 'groupID',
    checkReason: 'checkReason',
    consent: 'consent',
    contentCategory: 'contentCategory',
    ai: 'aiCats'
  };
  private lastRequest: 0;

  private tableMap = {
    behavior: this.behaviors,
    compliment: this.compliments,
    emergency: this.emergencies,
    group: this.groups,
    mitigation: this.mitigations,
    category: this.categories,
    quality: this.qualityCats,
    pi: this.piCats,
    assetType: this.assetType,
    checkType: this.checkType,
    groupID: this.groupID,
    checkReason: this.checkReason,
    consent: this.consent,
    contentCategory: this.contentCategory,
    ai: this.aiCats
  };

  constructor(
    private logger: NGXLogger,
    protected commsService: CommsService,
    private utils: UtilsService,
    private userService: UserService,
    private userdata: UserdataService,
    private subscriber: SubscriberService,
    private translate: TranslateService,
    private events: Events
  ) {
    super(commsService);
  }

  public refresh(updated: Number = 0) {
    if (updated && updated < this.lastRequest) {
      this.logger.log(`local accounts cache already up to date: ${updated}, ${this.lastRequest}`);
      return Promise.resolve(true);
    } else {
      return new Promise((resolve, reject) => {
        this.commsService.sendMessage({
          cmd: 'getMessageTemplates',
          includeDisabled: 1,
          lastRequest: this.lastRequest,
          sendTime: Date.now()
        }, false, false)
          .then((data) => {
            if (data && data.reqStatus === 'OK') {
              this.updateCache(data);
            }
            resolve(true);
          });
      });
    }
  }

  public updateCache(data) {
    _.each(this.tableMap, (container) => {
      container.data = [];
      container.lastRequest = data.result.timestamp;
    });
    this.lastRequest = data.result.timestamp;
    _.each(data.result.messageTemplates, (ref) => {
      const t = ref.type;
      if (this.tableMap.hasOwnProperty(t)) {
        this.tableMap[t].data.push(ref);
        this.tableMap[t].lastRequest = data.result.timestamp;
      } else {
        this.logger.log('Undefined setting type ' + t + ' received from backend');
      }
    });
    this.events.publish('ccs:settingsUpdate');
  }

  public clearCache() {
    _.each(this.tableMap, (ref: any) => {
      ref.data = {};
      ref.lastRequest = null;
    });
  }

  public getConsent(locationID?: any, activeOnly?: boolean, includeDisabled?: boolean) {
    return this.getSetting('consent', locationID, activeOnly, includeDisabled);
  }

  public getTiers(locationID?: any, activeOnly?: boolean, includeDisabled?: boolean) {
    return this.getSetting('tier', locationID, activeOnly, includeDisabled);
  }

  public getCategories(locationID?: any, activeOnly?: boolean, includeDisabled?: boolean) {
    return this.getSetting('category', locationID, activeOnly, includeDisabled);
  }

  public getQualityCats(locationID?: any, activeOnly?: boolean, includeDisabled?: boolean) {
    return this.getSetting('quality', locationID, activeOnly, includeDisabled);
  }

  public getAICats(locationID?: any, activeOnly?: boolean, includeDisabled?: boolean) {
    return this.getSetting('ai', locationID, activeOnly, includeDisabled);
  }

  public getPICats(locationID?: any, activeOnly?: boolean, includeDisabled?: boolean) {
    return this.getSetting('pi', locationID, activeOnly, includeDisabled);
  }

  public getCompliments(locationID?: any, activeOnly?: boolean, includeDisabled?: boolean) {
    return this.getSetting('compliment', locationID, activeOnly, includeDisabled);
  }

  public getAssetType(locationID?: any, activeOnly?: boolean, includeDisabled?: boolean) {
    return this.getSetting('assetType', locationID, activeOnly, includeDisabled);
  }

  public getBehaviors(locationID?: any, activeOnly?: boolean, includeDisabled?: boolean) {
    return this.getSetting('behavior', locationID, activeOnly, includeDisabled);
  }

  public getMitigations(locationID?: any, activeOnly?: boolean, includeDisabled?: boolean) {
    return this.getSetting('mitigation', locationID, activeOnly, includeDisabled);
  }

  public getGroupTypes(type: string, locationID?: any, activeOnly?: boolean, includeDisabled?: boolean) {
    return this.getSetting(type, locationID, activeOnly, includeDisabled);
  }

  public getCustomTags(locationID?: any, includeDisabled?: boolean) {
    return new Promise((resolve, reject) => {
      const when: number = Date.now();
      this.commsService.sendMessage({
        cmd: 'getTags',
        sendTime: when,
        countAppearances: 1
      }, false, false)
        .then((data) => {
          if (data && data.reqStatus === 'OK') {
            this.updateTagCache(data);
          }
          const items = [];
          _.each(this.customTags.data, (ref) => {
            if (includeDisabled || ref.disabledAt === 0) {
              items.push(ref);
            }
          });
          resolve(items);
        }).catch((err) => reject(err));
    });
  }

  /**
   * Takes an arbitrary field of tags and encode them, including adding new tags
   *
   * @param formData data from a form
   * @param tagField the name of the field to tag
   * @returns the updated form data
   */
  public async encodeTags(formData: any, tagField: string): Promise<any> {
    const selectedTags: any[] = _.isArray(formData[tagField]) ? formData[tagField] : [formData[tagField]];
    const customTags: string[] = _.filter(selectedTags, (tag: string) => _.includes(tag, '_custom_tag'));
    const tagIds: string[] = _.filter(selectedTags, (tag: string) => !_.includes(tag, '_custom_tag') && !_.isUndefined(tag));

    if (customTags.length) {
      return Promise.all(_.map(customTags, (tag: string) =>
        this.addCustomTag({tag: _.split(tag, '_custom_tag')[0]})
      )).then((ids: any) => {
        formData.tags = [
          ..._.map(tagIds, Number),
          ..._.map(ids, 'result.tagID')
        ];

        return Promise.resolve(formData);
      });
    } else {
      if (tagIds) {
        formData.tags = _.map(tagIds, Number);
      }
      return Promise.resolve(formData);
    }
  }

  public updateTagCache(data) {
    this.customTags.lastRequest = data.result.timestamp;
    this.customTags.data = data.result.tags;
    this.events.publish('ccs:tagsUpdate');
  }

  public getSettingSync(bucket: string, locationID?: any, activeOnly: boolean = false, sorted: boolean = false) {
    const lang = this.userdata.getLanguage();
    let bref = _.cloneDeep(this.tableMap[bucket]).data;
    if (locationID) {
      // when there is a location inactive items at the top level need to be left out!
      _.remove(bref, { active: 0 });
    }
    const ret = this._filterByLocation(bucket, bref, locationID, activeOnly);
    if (sorted) {
      ret.sort((a, b) => a.messageTitle.toLowerCase().localeCompare(b.messageTitle.toLowerCase(), lang));
    }
    return ret;
  }

  public getSetting(bucket: string, locationID?: any, activeOnly?: boolean, includeDisabled: boolean = true) {
    const bref = this.tableMap[bucket];
    return new Promise((resolve, reject) => {
      const when = Date.now();
      this.commsService.sendMessage({
        cmd: 'getMessageTemplates',
        includeDisabled: 1,
        types: JSON.stringify([bucket]),
        lastRequest: bref.lastRequest,
        sendTime: when
      }, false, false)
        .then((data) => {
          if (data && data.reqStatus === 'OK') {

            const dataObj = data.result.messageTemplates;
            this.translateData(dataObj);
            bref.lastRequest = when;
            bref.data = dataObj;
            this.utils.sortArray(bref.data, [
              {name: 'messageTitle', function: this.utils.toLowerCase}
            ]);
          }
          const items = [];
          _.each(bref.data, (ref) => {
            if (includeDisabled || ref.disabledAt === 0) {
              items.push(ref);
            }
          });
          if (!locationID) {
            resolve(items);
          } else {
            resolve(this._filterByLocation(bucket, items, locationID, activeOnly));
          }
        }).catch((err) => {
        reject(err);
      });
    });
  }

  public getItem(bucket: string, messageID: string | number, translate: boolean = true, mapTranslations: boolean = false) {
    const ref = this[bucket];
    if (ref) {
      let r: any = _.find(ref.data, <any>{messageID: +messageID});
      if (r && r.hasOwnProperty('translations')) {
        r = _.cloneDeep(r);
        if (translate) {
          const l = this.userdata.getLanguage();
          if (l !== this.subscriber.getDefaultLanguage()) {
            const t = _.find(r.translations, ['language', l]);
            if (t) {
              r.messageTitle = t.value;
            }
          }
        }
        if (mapTranslations) {
          _.each(r.translations, (ref) => {
            r[`translations_${ref.language}`] = ref.value;
          });
        }
      }
      return r;
    }
    return;
  }

  public handleAddMessage(fData) {
    fData.cmd = 'defineMessageTemplate';
    fData.sendTime = Date.now();
    return this._updateAndRefresh(fData, () => this.refresh());
  }

  public handleUpdateMessage(fData, locationID?: any) {
    // when the locationID is set, just update the location collection
    // of available messages of this type to include/exclude this item
    // based upon the 'active' flag
    fData.cmd = 'updateMessageTemplate';
    this.encodeCategoryFormData(fData);
    return this._updateAndRefresh(fData, () => this.refresh());
  }

  public handleAddCategory(fData) {
    fData.cmd = 'defineMessageTemplate';
    fData.type = 'category';
    fData.sendTime = Date.now();
    return this._updateAndRefresh(fData, () => this.refresh());
  }

  public handleDeleteCategory(fData) {
    fData.cmd = 'deleteMessageTemplate';
    return this._updateAndRefresh(fData, () => this.refresh());
  }

  public handleUpdateCategory(fData) {
    fData.type = 'category';
    this.encodeCategoryFormData(fData);
    return this.handleUpdateMessage(fData);
  }

  public handleUpdateBehavior(fData) {
    fData.type = 'behavior';
    return this.handleUpdateMessage(fData);
  }

  public handleDeleteBehavior(fData) {
    fData.cmd = 'deleteMessageTemplate';
    return this._updateAndRefresh(fData, () => this.refresh());
  }

  public handleAddBehavior(fData) {
    fData.type = 'behavior';
    return this.handleAddMessage(fData);
  }

  public handleAddMitigation(fData) {
    fData.type = 'mitigation';
    return this.handleAddMessage(fData);
  }

  public handleUpdateMitigation(fData) {
    fData.type = 'mitigation';
    return this.handleUpdateMessage(fData);
  }

  public handleDeleteMitigation(fData) {
    fData.cmd = 'deleteMessageTemplate';
    return this._updateAndRefresh(fData, () => this.refresh());
  }

  public handleUpdateAICat(fData) {
    fData.type = 'ai';
    this.encodeCategoryFormData(fData);
    return this.handleUpdateMessage(fData);
  }

  public handleDeleteAICat(fData) {
    fData.cmd = 'deleteMessageTemplate';
    return this._updateAndRefresh(fData, () => this.refresh());
  }

  public handleAddAICat(fData) {
    fData.type = 'ai';
    return this.handleAddMessage(fData);
  }

  public handleUpdateQualityCat(fData) {
    fData.type = 'quality';
    this.encodeCategoryFormData(fData);
    return this.handleUpdateMessage(fData);
  }

  public handleDeleteQualityCat(fData) {
    fData.cmd = 'deleteMessageTemplate';
    return this._updateAndRefresh(fData, () => this.refresh());
  }

  public handleAddQualityCat(fData) {
    fData.type = 'quality';
    return this.handleAddMessage(fData);
  }

  public handleAddCompliment(fData) {
    fData.type = 'compliment';
    return this.handleAddMessage(fData);
  }

  public handleUpdateCompliment(fData) {
    fData.type = 'compliment';
    this.encodeCategoryFormData(fData);
    return this.handleUpdateMessage(fData);
  }

  public handleDeleteCompliment(fData) {
    fData.cmd = 'deleteMessageTemplate';
    return this._updateAndRefresh(fData, () => this.refresh());
  }

  public handleAddGroupType(type: string, fData) {
    fData.type = type;
    return this.handleAddMessage(fData);
  }

  public handleUpdateGroupType(type: string, fData) {
    fData.type = type;
    return this.handleUpdateMessage(fData);
  }

  public handleDeleteGroupType(fData) {
    fData.cmd = 'deleteMessageTemplate';
    return this._updateAndRefresh(fData, () => this.refresh());
  }

  public handleUpdateConsent(fData) {
    fData.type = 'consent';
    if (this.consent.data.length) {
      fData.messageID = this.consent.data[0].messageID;
      return this.handleUpdateMessage(fData);
    } else {
      return this.handleAddMessage(fData);
    }
  }

  public addCustomTag(fData) {
    fData.cmd = 'addTag';
    return this.handleRequest(fData, () => this.getCustomTags());
  }

  public updateCustomTag(fData) {
    fData.cmd = 'updateTag';
    return this.handleRequest(fData, () => this.getCustomTags());
  }

  public deleteCustomTag(fData) {
    fData.cmd = 'deleteTag';
    return this.handleRequest(fData, () => this.getCustomTags());
  }

  public handleNewTagsByForm(formData, updateHandler: (formData) => Promise<any>): Promise<any> {
    const selectedTags: any[] = _.filter(_.isArray(formData.tags) ? formData.tags : [formData.tags]);
    const customTags: string[] = _.filter(selectedTags, (tag: string) => _.includes(tag, '_custom_tag'));
    const tagIds: string[] = _.filter(selectedTags, (tag: string) => !_.includes(tag, '_custom_tag'));

    if (customTags.length) {
      return Promise.all(_.map(customTags, (tag: string) => this.addCustomTag({tag: _.split(tag, '_custom_tag')[0]}))).then((ids: any) => {
        formData.tagIDs = [
          ..._.map(tagIds, Number),
          ..._.map(ids, 'result.tagID')
        ];

        return this.getCustomTags().then(() => updateHandler(formData));
      });
    } else {
      if (tagIds) {
        formData.tagIDs = _.map(tagIds, Number);
      }
      return updateHandler(formData);
    }
  }

  private _filterByLocation(bucket: string, data: any, locationIds?: number[] | number, activeOnly: boolean = true) {
    let filteredData: any[] = [];
    const activeDataItems: any[] = _.reject((activeOnly ? _.filter(data, 'active') : data), 'disabledAt');
    locationIds = _.flatten([locationIds]);
    this.translateData(activeDataItems);

    if ((<number[]>locationIds).length) {
      const locationPreferences: number[] | any = _.chain(locationIds)
        .map((locationId: number) => {
          const location: any = this.userService.findLocation(locationId) || {};
          return _.get(location.preferences, bucket);
        })
        .flatten()
        .uniq()
        .filter()
        .value();

      if ((<number[]>locationPreferences).length) {
        if (activeOnly) {
          filteredData = _.filter(activeDataItems, (dataItem: any) => _.includes(locationPreferences, dataItem.messageID));
        } else {
          filteredData = activeDataItems;
          // include everything, but be sure to set the toggles
          _.each(filteredData, (item: any) => {
            if (!_.includes(locationPreferences, item.messageID)) {
              item.active = false;
            }
          });
        }
      } else {
        filteredData = activeDataItems;
      }
    } else {
      filteredData = data;
    }

    return filteredData;
  }

  private _updateAndRefresh(fData, updateHandler): Promise<any> {
    const cmdOpts = _.cloneDeep(fData);

    cmdOpts.sendTime = Date.now();

    // roll up any translations
    const l = this.subscriber.getLanguages(true);
    if (l && l.length) {
      const t = [];
      _.each(l, (lang: string) => {
        const n = `translations_${lang}`;
        if (cmdOpts.hasOwnProperty(n)) {
          t.push({language: lang, value: cmdOpts[n]});
          delete cmdOpts[n];
        }
      });
      if (t.length) {
        cmdOpts.translations = JSON.stringify(t);
      } else {
        delete cmdOpts.translations;
      }
    }

    return new Promise((resolve, reject) => {
      this.commsService.sendMessage(cmdOpts, false, false)
        .then((data) => {
          if (data && data.reqStatus === 'OK') {
            updateHandler()
              .then((ores) => {
                resolve(data);
              })
              .catch((oerr) => {
                this.logger.error('update failed: ' + oerr);
              });
          } else {
            resolve(data);
          }
        })
        .catch((err) => {
          this.logger.error('update failed: ' + err);
          reject(err);
        });
    });
  }

  private translateData(data: any): void {
    const currentLanguage: string = this.translate.getDefaultLang();

    if (currentLanguage) {
      _.each(data, (dataItem: any) => {
        const translatedObject: any = _.find(dataItem.translations, {language: currentLanguage});
        if (translatedObject) {
          dataItem.messageTitle = translatedObject.value;
        }
      });
    }
  }

  private encodeCategoryFormData(data) {
    if (data.contentItems && _.isArray(data.contentItems)) {
      data.contentItems = JSON.stringify(data.contentItems);
    }
  }

}
