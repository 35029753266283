<ion-header no-border>
  <app-close-button #closeButton (onClicked)="back()"></app-close-button>
</ion-header>

<ion-content>
  <div class="navigation-block">
    <div class="header-content" *ngIf="!backNavigation && targetConfig?.data?.length > 1">
      <div>
        <span (click)="closeButton.back()" class="body-link">{{checkName}}</span><span class="spacer">/</span><span
        class="headline-large">{{"SHARED.Target" | translate}}</span>
      </div>
      <div class="filter-block left-spacer">
        <select2
          (valueChanged)="onTargetChange($event)"
          [data]="targetConfig.data | selectLangPipe"
          [options]="targetConfig.options"
          [value]="targetConfig.value"></select2>
      </div>
    </div>

    <div class="filter-block">
      <span class="descriptor upcase">{{'SHARED.Timespan'  | translate}} &nbsp;</span>
      <app-date-picker [dateConfig]="datePickerConfig" (onSelect)="onPeriodChange($event)"></app-date-picker>
    </div>
  </div>

  <app-tabs (currentTabChange)="onTabChange($event)" [(currentTab)]="currentTab" [tabs]="tabs"></app-tabs>

  <div class="dashboard-table-content">
    <div [hidden]="currentTab && currentTab.key !== 'complete'" class="table-container">
      <table class="display" [id]="tableIds.complete"></table>
    </div>

    <div [hidden]="currentTab && currentTab.key !== 'logged'" class="table-container">
      <table class="display" [id]="tableIds.logged"></table>
    </div>

    <div [hidden]="currentTab && currentTab.key !== 'corrective'" class="table-container">
      <table class="display" [id]="tableIds.corrective"></table>
    </div>

    <div [hidden]="currentTab && currentTab.key !== 'skipped'" class="table-container">
      <table class="display" [id]="tableIds.skipped"></table>
    </div>

    <div [hidden]="currentTab && currentTab.key !== 'missed'" class="table-container">
      <table class="display" [id]="tableIds.missed"></table>
    </div>
  </div>
</ion-content>
